import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './tutorial1VerifyBoard.mod.scss';
import { useIntl } from 'react-intl';


const Tutorial1VerifyBoard = ({
  onNextStep,
  onPrevStep,
}) => {
  const intl = useIntl();
  const [modalPage, setModalPage] = useState(0);

  const handleYes = () => {
    if (onNextStep) {
      onNextStep();
    }
  };

  const handleNo = (page) => {
    setModalPage(page);
  };

  const handleBack = () => {
    if (onPrevStep) {
      onPrevStep();
    }
  };

  return (
    <div className={styles.tutorial1VerifyBoardBG}>
      <div className={styles.tutorial1VerifyBoard}>
        {modalPage === 4 ? (
          <>
            <div className={styles.title}>{intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.titleError' })}</div>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.descriptionError4' }) }} />
            <div className={styles.image}><img src='/images/creabots/initiation/verifyBoard/verifyBoard3.gif' /></div>
            <div className={styles.buttons}>
              <button type="button" onClick={handleYes} className={clsx('btn', 'btn-primary', 'main-btn', styles.primaryButton)}>
                {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnOk' })}
              </button>
            </div>
          </>
        ) : ('')}
        {modalPage === 3 ? (
          <>
            <div className={styles.title}>{intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.titleError' })}</div>
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.descriptionError3' }) }} />
            <div className={styles.image}><img src='/images/creabots/initiation/verifyBoard/verifyBoard3.gif' /></div>
            <div className={styles.buttons}>
              <button type="button" onClick={handleYes} className={clsx('btn', 'btn-primary', 'main-btn', styles.primaryButton)}>
                {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnOk' })}
              </button>
            </div>
          </>
        ) : ('')}
        {modalPage === 2 ? (
          <>
            <div className={styles.title}>{intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.titleError' })}</div>
            <div className={styles.description}>{intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.descriptionError2' })}</div>
            <div className={styles.image}><img src='/images/creabots/initiation/verifyBoard/verifyBoard2.gif' /></div>
            <div className={styles.buttons}>
              <button type="button" onClick={handleYes} className={clsx('btn', 'btn-primary', 'main-btn', styles.primaryButton)}>
                {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnYes' })}
              </button>
              <button type="button" onClick={() => handleNo(4)} className={clsx('btn', 'btn-secondary', styles.secondaryButton)}>
                {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnNo' })}
              </button>
            </div>
          </>
        ) : ('')}
        {modalPage === 1 ? (
          <>
            <div className={styles.title}>{intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.titleError' })}</div>
            <div className={styles.description}>{intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.descriptionError1' })}</div>
            <div className={styles.image}><img src='/images/creabots/initiation/verifyBoard/verifyBoard1.gif' /></div>
            <div className={styles.buttons}>
              <button type="button" onClick={() => handleNo(2)} className={clsx('btn', 'btn-primary', 'main-btn', styles.primaryButton)}>
                {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnYes' })}
              </button>
              <button type="button" onClick={() => handleNo(3)} className={clsx('btn', 'btn-secondary', styles.secondaryButton)}>
                {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnNo' })}
              </button>
            </div>
          </>
        ) : ('')}
        {modalPage === 0 ? (
          <>
            <div className={styles.title}>{intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.title' })}</div>
            <div className={styles.description}>{intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.description' })}</div>
            <div className={styles.image}><img src='/images/creabots/initiation/verifyBoard/verifyBoard.png' /></div>
            <div className={styles.buttons}>
              <button type="button" onClick={handleYes} className={clsx('btn', 'btn-primary', 'main-btn', styles.primaryButton)}>
                {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnYes' })}
              </button>
              <button type="button" onClick={() => handleNo(1)} className={clsx('btn', 'btn-secondary', styles.secondaryButton)}>
                {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnNo' })}
              </button>
              {/* <div className={styles.repeatButton} onClick={handleBack}>
                {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnRepeat' })}
              </div> */}
            </div>
          </>
        ) : ('')}
      </div>
      <div className={styles.repeatButton} onClick={handleBack}>
        {intl.formatMessage({ id: 'creabots.tutorials.initiation.verifyBoard.btnRepeat' })}
      </div>
    </div>
  );
};


export default Tutorial1VerifyBoard;