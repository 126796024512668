import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { ConfirmRestartModal } from '@sections/creabots/components/ui/confirmRestartModal';
import styles from './tutorialProgressV2.mod.scss';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/styles';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.text.primary,
    fontSize: '1.4em',
    lineHeight: '20px',
    textAlign: 'center',
    fontFamily: 'DM Sans',
    letterSpacing: '0.03rem',
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const TutorialProgressV2 = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const onNextStep = props.onNextStep || null;
  const onPrevStep = props.onPrevStep || null;
  const currentStep = props.currentStep || 0;
  const totalStep = props.totalStep || 0;
  const progress = (totalStep > 0) ? (currentStep * 100) / totalStep : 0;
  const restartEnabled = !Boolean(props.onNextStep);

  const nextStep = () => {
    if (onNextStep) {
      onNextStep();
    }
  }

  const prevStep = () => {
    if (onPrevStep) {
      onPrevStep();
    }
  }

  const restart = () => {
    history.go(0);
  }

  const handleConfirmRestart = () => {
    setOpen(true);
  }

  const handleCancelRestart = () => {
    setOpen(false);
  }

  return (
    <div className={styles.tutorialProgress}>
      <div className={styles.progressRow}>
        <LinearProgress className={styles.progress} variant="determinate" value={progress} />
        <div className={styles.progressText}>{currentStep}/{totalStep}</div>
      </div>
      <div className={styles.progressActions}>
        <div className={styles.arrows}>
          <StyledTooltip title={intl.formatMessage({ id: 'creabots.prevStep' })} arrow>
            <div
              className={`${styles.prevStep} ${(!onPrevStep) ? styles.disabled : ''}`}
              onClick={prevStep}
            >
              <ChevronLeft />
            </div>
          </StyledTooltip>
          <StyledTooltip title={intl.formatMessage({ id: 'creabots.nextStep' })} arrow>
            <div
              className={`${styles.nextStep} ${(!onNextStep) ? styles.disabled : ''}`}
              onClick={nextStep}
            >
              <ChevronRight />
            </div>
          </StyledTooltip>
        </div>
        {restartEnabled ? (
          <>
            <StyledTooltip arrow title={intl.formatMessage({ id: 'creabots.restartStep' })} >
              <div className={styles.restart} onClick={handleConfirmRestart}><img src="/images/creabots/restart.svg" /> {intl.formatMessage({ id: 'common.restart' })}</div>
            </StyledTooltip>
            <ConfirmRestartModal isOpen={open} title={`${currentStep}/${totalStep}`} handleClose={handleCancelRestart} handleConfirm={restart} />
          </>
        ) : (null)}
      </div>
    </div>
  );
};

export default TutorialProgressV2;