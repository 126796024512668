import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectCreabots } from '../creabotsSlice';
import { Kits } from '../kits';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import {
  getProjectsProgress,
  INITIATION_KIT_TUTORIAL_ID_TYPE,
} from '@services/rest/creabots/getProjectsProgress';
import { CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits';
import firstSteps from '../kits/initiation/projects/firstSteps';

const projectsKits = [
  Kits['iniciacion'],
  Kits['automatizacion'],
  Kits['conduccion'],
];
export const useCardProject = () => {
  const intl = useIntl();
  const [isFirstStepProjectCompleted, setIsFirstStepProjectCompleted] =
    useState(false);
  const { userData } = useSelector(state => state.authReducer);
  const { kits, projects: userProjects } = useSelector(selectCreabots);
  const filteredProjects =
    projectsKits.find(kit => kit.id === kits.selected?.id)?.projects || [];

  const searchedKitProjects = (projectSearch: string | null) => {
    if (projectSearch === null) return filteredProjects;

    const translatedProjects = filteredProjects.map(pr => ({
      ...pr,
      name: intl.formatMessage({ id: pr.name }),
      description: intl.formatMessage({ id: pr.description }),
    }));

    return translatedProjects.filter(pr => {
      const searchTitle: string = pr.name;
      const searchDescription: string = pr.description;

      return (
        searchTitle.toLowerCase().includes(projectSearch.toLowerCase()) ||
        searchDescription.toLowerCase().includes(projectSearch.toLowerCase())
      );
    });
  };

  const searchedUserProjects = (projectSearch: string | null) => {
    if (projectSearch === null) return userProjects;
    if (kits.selected.id !== 0) return userProjects;

    return userProjects.filter(pr => {
      const searchTitle: string = pr.title;
      const searchDescription: string = format(
        new Date(pr.updatedAt),
        "d 'de' MMMM 'de' yyyy",
        {
          locale: es,
        }
      );

      return (
        searchTitle.toLowerCase().includes(projectSearch.toLowerCase()) ||
        searchDescription.toLowerCase().includes(projectSearch.toLowerCase())
      );
    });
  };

  useEffect(() => {
    const checkFirstStepsProgress = async () => {
      const projectsProgress = await getProjectsProgress();
      const firstStepsProject = projectsProgress.find(
        ({ creabotsKitId, tutorialId }) =>
          creabotsKitId === CREABOTS_KIT_ID_TYPE.INITIATION &&
          tutorialId === INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS
      );

      if (!firstStepsProject) {
        setIsFirstStepProjectCompleted(false);
      }

      const couldFinishFirstStepProject =
        firstStepsProject?.step === firstSteps.tutorial.length - 2;

      if (couldFinishFirstStepProject) {
        setIsFirstStepProjectCompleted(true);
      } else {
        setIsFirstStepProjectCompleted(false);
      }
    };

    if (userData && userData.data && userData.data.userId) {
      checkFirstStepsProgress();
    }
  }, [userData]);

  const getProjectURLByKit = (kitId: number, projectId: number) => {
    const name =
      projectsKits.find(kit => kit.id === kitId)?.urlName || 'iniciacion';
    return `/kits/${name}-${projectId}`;
  };

  const getUserProjectURLByBoardId = (boardId: number, projectId = null) => {
    const name =
      projectsKits.find(kit => kit.boardId === boardId)?.urlName ||
      'iniciacion';
    return `/kits/${name}/proyecto${projectId ? `-${projectId}` : ''}`;
  };

  function projectProgress(projectId: number, projectList: any[]) {
    const kitId = kits.selected?.id;
    const progress = kits.progress.find(
      (p: any) => p.creabotsKitId == kitId && p.tutorialId == projectId
    );
    if (!progress) {
      return 0;
    }
    const project = projectList.find(p => p.id == projectId);
    if (!project) {
      return 0;
    }
    // the last step is never completed, so it shouldn't count towards the progress
    return Math.round((100 * progress.step) / (project.tutorial.length - 2));
  }

  function isFirstStepsProject(project: any): boolean {
    return (
      project.creabotsKitId === CREABOTS_KIT_ID_TYPE.INITIATION && 
      project.tutorialId === INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS
    );
  }

  function hasProgressExcludingFirstSteps(): boolean {
    return kits.progress.some(
      (project: any) => 
        !isFirstStepsProject(project) &&
        !project.isDeleted
    );
  }


  return {
    hasProgressExcludingFirstSteps,
    projectsKits,
    isFirstStepProjectCompleted,
    searchedKitProjects,
    searchedUserProjects,
    getProjectURLByKit,
    getUserProjectURLByBoardId,
    projectProgress,
  };
};
