import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  EmailOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Link, useHistory } from 'react-router-dom';
import { LabsLogo } from '@assets/icons/labsLogo';
import { signInLabs } from '@services/rest/creabots';
import { Grid, IconButton, InputAdornment, InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import styles from './signIn.mod.scss';
import { useCheckLogged } from '@utils/hooks'

const muiStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(66, 179, 255, 0.5)',
      },
      color: '#000',
    },
    fontWeight: 500,
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
    },
    color: '#F45C3A',
  },
  errorText: {
    marginTop: '0.4em',
    paddingLeft: '1em',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#F45C3A',
    fontFamily: 'DM Sans, sans-serif',
  },
}));

const initialValues = {
  email: '',
  password: '',
};

const initialErrorsValues = {
  email: '',
  password: '',
  request: '',
};

const SignInContainer = () => {
  const [fields, setFields] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrorsValues);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const mui = muiStyles();
  const intl = useIntl();
  const { data } = useCheckLogged(false);

  // if we're already logged in, we directly go to the next screen
  useEffect(() => {
    if (data) history.push('/kits/perfiles')
  }, [data])

  const handleChangeField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!fields.password) {
      setErrors((prev) => ({
        ...prev,
        password: intl.formatMessage({ id: 'creabots.validations.enterPassword' }),
      }));
    }
    if (!fields.email) {
      setErrors((prev) => ({
        ...prev,
        email: intl.formatMessage({ id: 'creabots.validations.enterEmail' }),
      }));
    }

    if (!fields.password && !fields.email) {
      setErrors({
        password: intl.formatMessage({ id: 'creabots.validations.enterPassword' }),
        email: intl.formatMessage({ id: 'creabots.validations.enterEmail' }),
      });
    }
    if (!fields.password || !fields.email) return;
    try {
      setErrors(initialErrorsValues);
      setIsLoading(true);
      await signInLabs({
        email: fields.email,
        password: fields.password,
      });
      history.push('/kits/perfiles');
    } catch (error) {
      const errorResponse = error.response;
      if (
        errorResponse?.status === 401
        && errorResponse.data.message === 'not-finded-email'
      ) {
        setErrors((prev) => ({
          ...prev,
          request: errorResponse.data.message,
        }));
        return;
      }
      if (
        errorResponse?.status === 401
        && errorResponse.data.message === 'invalid-password'
      ) {
        setErrors((prev) => ({
          ...prev,
          password:
            'Contraseña incorrecta. Intentar nuevamente o hacer clic en “Olvide la contraseña” para restablecerla.',
        }));
        return;
      }
      setErrors((prev) => ({
        ...prev,
        request:
          errorResponse?.data?.message
          || intl.formatMessage({ id: 'common.errors.somethingWentWrong' }),
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className={styles.container}>
      <header className={styles.signInHeader}>
        <h2 className={styles.creabotsLabel}>
          <LabsLogo />
        </h2>
      </header>
      <main className={styles.card}>
        <article className={styles.cardHeader}>
          <h1 className={styles.title}>{intl.formatMessage({ id: 'creabots.auth.signIn' })}</h1>
        </article>
        <form className={styles.cardContent} onSubmit={handleSubmit}>
          <section className={styles.inputsContainer}>

            <Grid container style={{ gap: '3em', marginTop: '6.5em' }}>
              <Grid
                xs={12}
                item
                className={clsx('form-group bmd-form-group', styles.inputControl)}
              >
                <InputLabel
                  shrink={false}
                  htmlFor="email"
                  className={clsx('bmd-label-static', styles.inputLabel)}
                >
                  {intl.formatMessage({ id: 'creabots.labels.parentEmail' })}
                </InputLabel>
                <TextField
                  type="email"
                  name="email"
                  id="email"
                  fullWidth
                  variant="outlined"
                  value={fields.email}
                  error={!!errors.email}
                  onChange={(e) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (e.target.value.match(pattern) === null) {
                      setErrors((prev) => ({
                        ...prev,
                        email: 'El email es inválido.',
                      }));
                    } else {
                      setErrors((prev) => ({ ...prev, email: '' }));
                    }
                    handleChangeField(e);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailOutlined
                          className={clsx(
                            styles.inputIcon,
                            (errors.email || errors.request) && styles.error,
                          )}
                        />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      fontWeight: 500,
                      color: errors.email ? '#F45C3A' : 'black',
                    },
                  }}
                  className={clsx(mui.root, errors.email && mui.error)}
                />

                {errors.email && (
                <p className={mui.errorText}>{errors.email}</p>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                className={clsx('form-group bmd-form-group', styles.inputControl)}
              >
                <InputLabel
                  shrink={false}
                  htmlFor="password"
                  className={clsx('bmd-label-static', styles.inputLabel)}
                >
                  {intl.formatMessage({ id: 'creabots.labels.password' })}
                </InputLabel>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  fullWidth
                  variant="outlined"
                  value={fields.password}
                  error={!!errors.password}
                  onChange={handleChangeField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="cambiador de visibiliad de contraseña"
                          onClick={() => setShowPassword((prev) => !prev)}
                          style={{ padding: 0, margin: 0 }}
                        >
                          {showPassword ? (
                            <VisibilityOutlined
                              className={clsx(
                                styles.inputIcon,
                                (errors.password || errors.request) && styles.error,
                              )}
                            />
                          ) : (
                            <VisibilityOffOutlined
                              className={clsx(
                                styles.inputIcon,
                                (errors.password || errors.request) && styles.error,
                              )}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      fontWeight: 500,
                      color: errors.password ? '#F45C3A' : 'black',
                    },
                  }}
                  className={clsx(mui.root, errors.password && mui.error)}
                />

                {errors.password && (
                <p className={mui.errorText}>{errors.password}</p>
                )}
              </Grid>
            </Grid>


            <Link
              to="/kits/recuperar-contraseña"
              className={styles.recoverPassword}
            >
              {intl.formatMessage({ id: 'creabots.auth.forgotPassword' })}
            </Link>
          </section>

          {errors.request && (
            <article className={styles.errorRequest}>
              {errors.request === 'not-finded-email' ? (
                <>
                  <p className={styles.title}>{intl.formatMessage({ id: 'creabots.auth.dontFindEmail' })}</p>
                  <p className={styles.description}>
                    {intl.formatMessage({ id: 'creabots.auth.dontFindEmailDescription' })}{' '}
                    <Link to="/kits/registrarse">{intl.formatMessage({ id: 'creabots.auth.createAccount' })}</Link>.
                  </p>
                </>
              ) : (
                <p className={styles.description}>{errors.request}</p>
              )}
            </article>
          )}

          <section className={styles.formFooter}>
            <p className={styles.termsAndConditions}>
              {intl.formatMessage({ id: 'creabots.auth.registeringAcept' })}{' '}
              <Link to="https://docs.google.com/document/d/1kR_DtKnqoHXIB6JZ6PNf2FWpFyyWlzq0vFUrhax076I/edit?usp=sharing">
                {intl.formatMessage({ id: 'creabots.auth.termsAndConditions' })}
              </Link>{' '}
              {intl.formatMessage({ id: 'creabots.auth.andThe' })}{' '}
              <Link to="https://educabot.com/politicas/">
                {intl.formatMessage({ id: 'creabots.auth.privatePolicy' })}
              </Link>{' '}
              {intl.formatMessage({ id: 'common.of' })} Educabot Labs.
            </p>
            <button
              type="submit"
              className={styles.btnPrimary}
              disabled={isLoading}
            >
              {intl.formatMessage({ id: 'common.continue' })}
            </button>
          </section>
        </form>
        <article className={styles.cardFooter}>
          <h4 className={styles.haveAccount}>
            {intl.formatMessage({ id: 'creabots.auth.dontHaveAccount' })}{' '}
            <Link to="/kits/registrarse">{intl.formatMessage({ id: 'creabots.auth.createAccount' })}</Link>
          </h4>
        </article>
      </main>
    </section>
  );
};

export default SignInContainer;
