/* eslint no-restricted-syntax: 0 */
/* eslint no-await-in-loop: 0 */
/* eslint no-else-return: 0 */
import * as tfcore from '@tensorflow/tfjs-core';
import * as tf from '@tensorflow/tfjs';
import * as mpHands from '@mediapipe/hands';
import * as handdetection from '@tensorflow-models/hand-pose-detection';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import * as seedrandom from 'seedrandom';
import { Camera } from '../camera';
import { STATE, MODEL_TYPES, MODEL_BACKEND_MAP, MEDIAPIPE_HANDS_CONFIG, BACKEND_FLAGS_MAP } from '../params';
import { setBackendAndEnvFlags, normalizePlain, flatOneHot, fisherYates, convertToTfDataset } from '../util';

tfjsWasm.setWasmPaths(`https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`);


export default class HandsHandler {
  modelType = MODEL_TYPES.HANDS;

  state = STATE;

  seed = seedrandom('testSuite');

  trainingParams = {
    denseUnits: 100,
    epochs: 50,
    learningRate: 0.1,
    batchSize: 16,
  };

  wrapper = null;

  detector = null;

  camera = null;

  rafId = null;

  hands = null;

  classifying = false;

  predictionCallback = null;

  model = null;

  samples = [];

  trainDataset = null;

  validationDataset = null;

  classNamesToIndex = {};

  constructor(wrapper, predictionCallback) {
    this.wrapper = wrapper;
    this.predictionCallback = predictionCallback;
  }

  init = async () => {
    console.log('Handling model Hands');
    await this.initDefaultValueMap();
    this.camera = await Camera.setupCamera(this.state.camera, this.wrapper);
    await setBackendAndEnvFlags(this.state.flags, this.state.backend);
    this.renderPrediction();
    if (!this.detector) {
      this.detector = await this.createDetector();
    }
  }

  initDefaultValueMap = async () => {
    const newState = { ...STATE };

    newState.model = handdetection.SupportedModels.MediaPipeHands;
    const backends = MODEL_BACKEND_MAP[newState.model];
    newState.backend = backends[0];
    newState.modelConfig = MEDIAPIPE_HANDS_CONFIG;
    newState.modelConfig.type = 'full';
    newState.modelConfig.maxNumHands = 1;

    // Clean up the cache to query tunable flags' default values.
    const TUNABLE_FLAG_DEFAULT_VALUE_MAP = {};
    newState.flags = {};
    for (let index = 0; index < BACKEND_FLAGS_MAP[newState.backend].length; index += 1) {
      const flag = BACKEND_FLAGS_MAP[newState.backend][index];
      TUNABLE_FLAG_DEFAULT_VALUE_MAP[flag] = await tfcore.env().getAsync(flag);
    }

    // Initialize newState.flags with tunable flags' default values.
    for (const flag in TUNABLE_FLAG_DEFAULT_VALUE_MAP) {
      if (BACKEND_FLAGS_MAP[newState.backend].indexOf(flag) > -1) {
        newState.flags[flag] = TUNABLE_FLAG_DEFAULT_VALUE_MAP[flag];
      }
    }

    this.state = newState;
  }

  createDetector = () => {
    const state = this.state;
    const runtime = state.backend.split('-')[0];
    switch (state.model) {
      default:
      case handdetection.SupportedModels.MediaPipeHands:
        if (runtime === 'mediapipe') {
          return handdetection.createDetector(state.model, {
            runtime,
            modelType: state.modelConfig.type,
            maxHands: state.modelConfig.maxNumHands,
            solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/hands@${mpHands.VERSION}`,
          });
        } else if (runtime === 'tfjs') {
          return handdetection.createDetector(state.model, {
            runtime,
            modelType: state.modelConfig.type,
            maxHands: state.modelConfig.maxNumHands,
          });
        }
    }
    return null;
  }

  renderPrediction = async () => {
    await this.renderResult();
    this.rafId = requestAnimationFrame(this.renderPrediction);
    return null;
  }

  renderResult = async () => {
    const camera = this.camera;
    if (camera.video.readyState < 2) {
      await new Promise((resolve) => {
        camera.video.onloadeddata = () => {
          resolve(true);
        };
      });
    }

    this.hands = null;

    if (this.detector != null) {
      try {
        this.hands = await this.detector.estimateHands(camera.video, { flipHorizontal: false });
        if (this.classifying) {
          await this.classify();
        }
      } catch (error) {
        this.detector.dispose();
        this.detector = null;
        console.error('================', error);
      }
    }

    camera.drawCtx();

    if (this.hands && this.hands.length > 0 && this.state.drawEstimation) {
      camera.drawResults(this.hands);
    }
  }

  classify = async () => {
    if (this.model && this.predictionCallback) {
      const results = {};
      if (this.hands.length > 0) {
        const embeddings = tf.tensor([normalizePlain(this.hands[0].keypoints3D)]);
        const logits = this.model.predict(embeddings);
        const values = await logits.data();

        for (let i = 0; i < values.length; i += 1) {
          const className = Object.keys(this.classNamesToIndex)[i];
          results[className] = values[i].toFixed(4);
        }
        // console.log('==========results', results);
        embeddings.dispose();
        logits.dispose();
      }
      this.predictionCallback(results);
    }
  }

  startVideo = async () => {
    this.camera = await Camera.setupCamera(this.state.camera, this.wrapper);
    this.renderPrediction();
    if (!this.detector) {
      this.detector = await this.createDetector();
    }
  }

  stopVideo = () => {
    window.cancelAnimationFrame(this.rafId);
    this.rafId = null;

    if (this.detector != null) {
      this.detector.dispose();
      this.detector = null;
    }

    if (this.camera != null) {
      this.camera.video.srcObject.getVideoTracks().forEach((track) => track.stop());
      this.camera.clearCtx();
    }

    if (this.predictionCallback && this.classifying) {
      this.predictionCallback({});
    }
  }

  clearAllSamples = () => {
    this.classifying = false;
    this.samples = [];
    this.model = null;
  }

  startClassifying = () => {
    this.classifying = true;
  }

  stopClassifying = () => {
    this.classifying = false;
  }

  setTrainingParams = (params) => {
    this.trainingParams = {
      ...this.trainingParams,
      ...params,
    };
  }

  getTrainingParams = () => {
    return this.trainingParams;
  }

  addSample = (className) => {
    if (this.detector && this.hands && this.hands.length > 0 && className) {
      const sample = normalizePlain(this.hands[0].keypoints3D);

      if (this.classNamesToIndex[className] === undefined) {
        this.classNamesToIndex[className] = Object.keys(this.classNamesToIndex).length || 0;
      }
      const index = this.classNamesToIndex[className];
      if (!this.samples[index]) {
        this.samples[index] = [];
      }
      this.samples[index].push(sample);
      return this.samples[index].length || 0;
    }
    return 0;
  }

  getSamples = () => {
    return {
      modelType: this.modelType,
      samples: this.samples,
    };
  }

  setSamples = (samples, classesArray) => {
    if (samples.modelType === this.modelType) {
      this.samples = samples.samples;
      const nSamples = {};
      this.samples.map((sampleArr, classIndex) => {
        if (sampleArr && classesArray[classIndex] !== undefined) {
          const className = classesArray[classIndex][0]
          if (this.classNamesToIndex[className] === undefined) {
            this.classNamesToIndex[className] = classIndex;
          }
          nSamples[className] = sampleArr.length;
        }
        return null
      });
      return Object.keys(nSamples).length > 0 ? nSamples : false;
    } else {
      return false;
    }
  }

  prepare = () => {
    for (const classes in this.samples) {
      if (classes.length === 0) {
        throw new Error('Add some examples before training');
      }
    }

    const datasets = convertToTfDataset(this.samples, this.seed);
    this.trainDataset = datasets.trainDataset;
    this.validationDataset = datasets.validationDataset;
  }

  train = async (callbacks) => {
    this.prepare();
    const inputSize = this.samples[0][0].length;

    // const callbacks = {};
    // callbacks.onTrainEnd = (logs) => {
    //   console.log('==========onTrainEnd', logs);
    // };
    // callbacks.onEpochBegin = (epoch, logs) => {
    //   console.log('==========onEpochBegin', epoch, logs);
    // };
    // callbacks.onEpochEnd = (epoch, logs) => {
    //   console.log('==========onEpochEnd', epoch, logs);
    // };

    // in case we need to use a seed for predictable training
    let varianceScaling;
    if (this.seed) {
      varianceScaling = tf.initializers.varianceScaling({ seed: 3.14 });
    } else {
      varianceScaling = tf.initializers.varianceScaling({});
    }

    this.model = tf.sequential({
      layers: [
        // Layer 1.
        tf.layers.dense({
          inputShape: [inputSize],
          units: parseInt(this.trainingParams.denseUnits, 10),
          activation: 'relu',
          kernelInitializer: varianceScaling, // 'varianceScaling'
          useBias: true,
        }),
        // Layer 2 dropout
        tf.layers.dropout({ rate: 0.5 }),
        // Layer 3. The number of units of the last layer should correspond
        // to the number of classes we want to predict.
        tf.layers.dense({
          units: this.samples.length,
          kernelInitializer: varianceScaling, // 'varianceScaling'
          useBias: false,
          activation: 'softmax',
        }),
      ],
    });

    this.model.setUserDefinedMetadata({ classNames: this.classNamesToIndex });

    const optimizer = tf.train.rmsprop(parseFloat(this.trainingParams.learningRate));
    this.model.compile({
      optimizer,
      loss: 'categoricalCrossentropy',
      metrics: ['accuracy'],
    });

    if (!(parseInt(this.trainingParams.batchSize, 10) > 0)) {
      throw new Error('Batch size is 0 or NaN. Please choose a non-zero fraction');
    }

    const trainData = this.trainDataset.batch(parseInt(this.trainingParams.batchSize, 10));
    const validationData = this.validationDataset.batch(parseInt(this.trainingParams.batchSize, 10));

    await this.model.fitDataset(trainData, {
      epochs: parseInt(this.trainingParams.epochs, 10),
      validationData,
      callbacks,
    });

    optimizer.dispose(); // cleanup

    return this.model;
  }

  save = async (name = 'modelo-educabot-manos') => {
    // Save to local storage
    const saveResults = await this.model.save(`localstorage://${name}`);
  }

  load = async (name = 'modelo-educabot-manos') => {
    // Load from local storage
    const loadedModel = await tf.loadLayersModel(`localstorage://${name}`);
  }

  export = async (name = 'modelo-educabot-manos') => {
    const saveResults = await this.model.save(`downloads://${name}`);
  }

  import = async (json, weights) => {
    this.model = await tf.loadLayersModel(tf.io.browserFiles([json, weights]));
    // this.model = await tf.loadLayersModel(tf.io.browserFiles([json.files[0], weights.files[0]]));
    if (this.model) {
      this.classNamesToIndex = this.model.getUserDefinedMetadata().classNames;
      return this.classNamesToIndex;
    } else {
      return false;
    }
  }
}
