import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1'
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, CODE_CHECK_TYPE, COMMON_CLASSES, INITIATION_PROJECTS_TYPE, KIT_NAME, MODAL_TYPE, Project } from '@sections/creabots/types/kits'
import * as routes from '@educabot/educablocks-cosmos';
import { getInitiationConfigForBlock, getInitiationElementForBlock } from '../config'
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.initiation.projects.rockPaperScissors'
const imgBaseUrl = '/images/creabots/initiation/projects/rockPaperScissors/steps'

const rockPaperScissors: Project = {
  id: 4,
  name: `${intlPrefix}.name`,
  boardId: BOARD_ID_TYPE.INITIATION,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}/images/creabots/initiation/projects/rockPaperScissors/project_image.png`,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/piedra-papel-o-tijera.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.INITIATION, projectName: INITIATION_PROJECTS_TYPE.ROCK_PAPER_SCISSORS, totalSteps: 13 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidsetup(){}voidloop(){if(0==0){}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1, { xOffset: 40 }),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 70,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 0),
        completionCode: 'voidsetup(){pinMode(A0,INPUT);}voidloop(){if(!digitalRead(A0)==0){}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 0, { xOffset: 40 }),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 95,
            yOffset: 77,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        completionCode: 'voidsetup(){pinMode(A0,INPUT);}voidloop(){if(!digitalRead(A0)==1){}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 425,
            yOffset: 85,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 2, { x: 190, ignoreBlock: true }),
        completionCodeCheck: CODE_CHECK_TYPE.VARIABLE,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.ADD_VARIABLE_BTN_CLASS,
            xOffset: 40,
            yOffset: -18,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 2),
        completionCode: 'voidloop\\(\\)\\{[^}]*String\\w+=0;*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 130,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        completionCode: 'voidloop\\(\\)\\{[^}]*int\\w+=0;*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 335,
            yOffset: 135,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 3),
        completionCode: 'voidloop\\(\\)\\{[^}]*int\\w+=random\\(1,11\\);*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 430,
            yOffset: 135,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.gif`,
        completionCode: 'voidloop\\(\\)\\{[^}]*int\\w+=random\\(1,4\\);*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 590,
            yOffset: 135,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'if\\(\\!digitalRead\\(A0\\)==1\\)\\{[^}]*int\\w+=random\\([^)]*\\);if\\(0==0\\)\\{\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1, { xOffset: 40 }),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 155,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        completionCode: 'if\\(\\!digitalRead\\(A0\\)==1\\)\\{[^}]*int\\w+=random\\([^)]*\\);if\\([^0]*==0\\)\\{\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3, { xOffset: 40, yOffset: 0 }),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 110,
            yOffset: 176,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        completionCode: 'if\\(\\!digitalRead\\(A0\\)==1\\)\\{[^}]*int\\w+=random\\([^)]*\\);if\\([^0]*==1\\)\\{\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 300,
            yOffset: 176,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0),
        completionCode: 'if\\(\\!digitalRead\\(A0\\)==1\\)\\{[^}]*int\\w+=random\\([^)]*\\);if\\([^0]*==1\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 80,
            yOffset: 205,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.png`,
        completionCode: 'staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 115,
            yOffset: 280,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        completionCode: 'if\\([^0]*==1\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}else\\{\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 50,
            yOffset: 370,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        completionCode: 'if\\([^0]*==1\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}elseif\\(false\\)\\{\\}else\\{\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 0, y: -50 },
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 50,
            yOffset: 427,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.LOGIC, 0, { y: -100 }),
        completionCode: 'if\\([^0]*==1\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}elseif\\(0==1\\)\\{\\}else\\{\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.LOGIC, 0, { xOffset: 20, yOffset: 10 }),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 110,
            yOffset: 372,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3, { y: -100 }),
        completionCode: 'if\\([^0]*==1\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}elseif\\([^0]*==1\\)\\{\\}else\\{\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3, { xOffset: 40, yOffset: 25 }),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 127,
            yOffset: 372,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        completionCode: 'if\\([^0]*==1\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}elseif\\([^0]*==2\\)\\{\\}else\\{\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 0, y: -100 },
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 320,
            yOffset: 375,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0, { y: -170 }),
        completionCode: 'if\\([^0]*==1\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}elseif\\([^0]*==2\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}else\\{\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 70,
            yOffset: 410,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.gif`,
        completionCode: 'staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\};staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\};',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 0, y: -170 },
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 117,
            yOffset: 480,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0, { y: -250 }),
        completionCode: 'if\\([^0]*==1\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}elseif\\([^0]*==2\\)\\{matrix_8x8.clear\\(\\);[^}]*\\}else\\{matrix_8x8.clear\\(\\);[^}]*\\}*\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 70,
            yOffset: 605,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.gif`,
        completionCode: 'staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\};staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\};staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\};',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
        canvasDisplacement: { x: 0, y: -250 },
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 117,
            yOffset: 670,
          },
        },
      ],
    },
    // Step 11.
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/step11.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 12.
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/step12.gif`,
      },
    },
  ],
}

export default rockPaperScissors