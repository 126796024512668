import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, CODE_CHECK_TYPE, COMMON_CLASSES, DRIVING_PROJECTS_TYPE, KIT_NAME, MODAL_TYPE, Project } from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getDrivingConfigForBlock, getDrivingElementForBlock } from '../config';
import { differentThan1000Regex } from '@sections/creabots/features/utils/commonRegexs';
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.driving.projects.smartVehicle';
const imgBaseUrl = '/images/creabots/driving/projects/smartVehicle';

export const smartVehicle: Project = {
  id: 1,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.MOVEMENT,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/vehiculo-inteligente.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.DRIVING, projectName: DRIVING_PROJECTS_TYPE.SMART_VEHICLE, totalSteps: 23 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 15,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(1000\\);\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 50,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 132,
            yOffset: 67,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Avanzar\\(255\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 90,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.gif`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 135,
            yOffset: 110,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);delay\\(1000\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 140,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);delay\\(1000\\);Bhoot_Avanzar\\(255\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 180,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);delay\\(1000\\);Bhoot_GirarIzquierda\\(255\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 130,
            yOffset: 195,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);delay\\(1000\\);Bhoot_GirarIzquierda\\(255\\);delay\\(1000\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 225,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);delay\\(1000\\);Bhoot_GirarIzquierda\\(255\\);delay\\(${differentThan1000Regex}\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 130,
            yOffset: 240,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);delay\\(1000\\);Bhoot_GirarIzquierda\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Avanzar\\(255\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 260,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);delay\\(1000\\);Bhoot_GirarIzquierda\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 135,
            yOffset: 285,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);matrix_8x8\\.begin\\(0x70\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);delay\\(1000\\);Bhoot_GirarIzquierda\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);matrix_8x8\\.clear\\(\\);matrix_8x8\\.setRotation\\(4\\);matrix_8x8\\.drawBitmap\\(0,0,bitmapMatrix,8,8,LED_ON\\);matrix_8x8\\.writeDisplay\\(\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 310,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        completionCode: `staticuint8_tbitmapMatrix\\[\\]=\\{[^}]*1+[^}]*\\};voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);matrix_8x8\\.begin\\(0x70\\);Bhoot_Avanzar\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);delay\\(1000\\);Bhoot_GirarIzquierda\\(255\\);delay\\(${differentThan1000Regex}\\);Bhoot_Frenar\\(\\);matrix_8x8\\.clear\\(\\);matrix_8x8\\.setRotation\\(4\\);matrix_8x8\\.drawBitmap\\(0,0,bitmapMatrix,8,8,LED_ON\\);matrix_8x8\\.writeDisplay\\(\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 90,
            yOffset: 390,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.gif`,
      },
    },
  ],
};
