import { useEffect, useState, useRef } from 'react'
import { MODEL_TYPES } from './machineLearning/params';
import HandsHandler from './machineLearning/handlers/handsHandler';
import ImagesHandler from './machineLearning/handlers/imagesHandler';
import ObjectsHandler from './machineLearning/handlers/objectsHandler';


export default function useMachineLearning({ trainingParams = {}, wrapperClass = 'canvas-wrapper', getPredictionCallback = null }) {
  const handler = useRef(null)

  const getModelHandler = (modelType) => {
    switch (modelType) {
      case MODEL_TYPES.HANDS:
        return new HandsHandler(wrapperClass, getPredictionCallback);
      case MODEL_TYPES.IMAGES:
        return new ImagesHandler(wrapperClass, getPredictionCallback);
      case MODEL_TYPES.OBJECTS:
        return new ObjectsHandler(wrapperClass, getPredictionCallback);
      default:
        throw new Error(`Unknown model type: ${modelType}`);
    }
  }

  const init = async (modelType) => {
    handler.current = getModelHandler(modelType);
    await handler.current.init();
  }

  const getDefaultTrainingParams = () => {
    return handler.current.getTrainingParams();
  }

  const startVideo = async () => {
    if (handler.current) {
      await handler.current.startVideo();
    }
  }

  const stopVideo = () => {
    if (handler.current) {
      handler.current.stopVideo();
    }
  }

  const addSample = async (className) => {
    return handler.current.addSample(className);
  }

  const getSamples = (className) => {
    return handler.current.getSamples();
  }

  const setSamples = (samples, classes) => {
    return handler.current.setSamples(samples, classes);
  }

  const clearAllSamples = () => {
    handler.current.clearAllSamples();
  }

  const exportModel = async (name) => {
    await handler.current.export(name);
  }

  const importModel = async (json, weights) => {
    const userClassNames = await handler.current.import(json, weights);
    return userClassNames;
  }

  const train = async (callbacks) => {
    await handler.current.train(callbacks);
  }

  const startClassifying = () => {
    handler.current.startClassifying();
  }

  const stopClassifying = () => {
    handler.current.stopClassifying();
  }

  useEffect(() => {
    if (handler.current) {
      handler.current.setTrainingParams(trainingParams);
    }
  }, [trainingParams]);

  return {
    getDefaultTrainingParams,
    init,
    startVideo,
    stopVideo,
    addSample,
    getSamples,
    setSamples,
    clearAllSamples,
    importModel,
    exportModel,
    train,
    startClassifying,
    stopClassifying,
  };
}
