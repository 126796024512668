import React, { useState, useRef, useEffect } from 'react';
import { Player, ControlBar } from 'video-react';
import BigPlayButton from 'video-react/lib/components/BigPlayButton';
import styles from './tutorial1Step1.mod.scss';
import { useIntl } from 'react-intl';
import TutorialProgress from '@sections/creabots/components/tutorialProgress';
import { useBlockly } from '@modules/blockly/features/hooks';


const Tutorial0Step1 = ({
  onNextStep,
  currentStep,
  totalSteps,
  videoUrl,
}) => {
  const intl = useIntl();
  const player = useRef(null);
  const [isFinished, setIsFinished] = useState(false);
  const blocklyState = useBlockly();

  const playVideo = () => {
    if (player.current) {
      player.current.play();
    }
  };

  const pauseVideo = () => {
    if (player.current) {
      player.current.pause();
    }
  };

  const handleNext = () => {
    if (onNextStep) {
      onNextStep();
    }
  };

  const handleStateChange = (state) => {
    if (state.duration - state.currentTime <= 0) {
      handleNext();
    }
  }

  useEffect(() => {
    if (player) {
      player.current.subscribeToStateChange(handleStateChange.bind(this));
    }
  }, []);

  useEffect(() => {
    if (blocklyState.loading === false) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [blocklyState.loading]);

  return (
    <div className={styles.tutorial1Step1}>
      <TutorialProgress showStepsCount={true} currentStep={currentStep + 1} totalStep={totalSteps} onNextStep={handleNext} />
      <div className={styles.video}>
        <Player
          ref={player}
          // autoPlay
          width="100%"
          height="100%"
          fluid={false}
          preload="auto"
          muted={false}
        >
          <source src={videoUrl} />
          <ControlBar disableCompletely={true} />
          <BigPlayButton position="center" className="big-play-button-hide" />
        </Player>
      </div>
      {(isFinished) && (
        <div className={styles.actions}>
          <button onClick={handleNext} className='btn btn-primary main-btn'>{intl.formatMessage({ id: 'common.continue' })}</button>
        </div>
      )}
    </div>
  );
};


export default Tutorial0Step1;