import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, CODE_CHECK_TYPE, COMMON_CLASSES, DRIVING_PROJECTS_TYPE, KIT_NAME, MODAL_TYPE, Project } from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getDrivingConfigForBlock, getDrivingElementForBlock } from '../config';
import { differentThan0Regex, lessThan1000 } from '@sections/creabots/features/utils/commonRegexs';
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.driving.projects.lunarExplorer';
const imgBaseUrl = '/images/creabots/driving/projects/lunarExplorer';

export const lunarExplorer: Project = {
  id: 2,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.MOVEMENT,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/explorador-lunar.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.DRIVING, projectName: DRIVING_PROJECTS_TYPE.LUNAR_EXPLORER, totalSteps: 23 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 70,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(0==0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 105,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)==0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 95,
            yOffset: 125,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 410,
            yOffset: 135,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 470,
            yOffset: 135,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Avanzar\\(255\\);\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 180,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.gif`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 145,
            yOffset: 190,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 215,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(0==0\\)\\{\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 255,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 3),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,11\\)==0\\)\\{\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 110,
            yOffset: 272,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.gif`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,3\\)==0\\)\\{\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 270,
            yOffset: 272,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.gif`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,3\\)==(1|2)\\)\\{\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 400,
            yOffset: 272,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,3\\)==(1|2)\\)\\{Bhoot_Avanzar\\(255\\);\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 70,
            yOffset: 310,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.gif`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,3\\)==(1|2)\\)\\{Bhoot_GirarDerecha\\(255\\);\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 160,
            yOffset: 318,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/steps/step10.png`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,3\\)==(1|2)\\)\\{Bhoot_GirarDerecha\\(255\\);\\}else\\{\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 50,
            yOffset: 360,
          },
        },
      ],
    },
    // Step 11.
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/steps/step11.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,3\\)==(1|2)\\)\\{Bhoot_GirarDerecha\\(255\\);\\}else\\{Bhoot_Avanzar\\(255\\);\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 72,
            yOffset: 400,
          },
        },
      ],
    },
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/steps/step11.gif`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,3\\)==(1|2)\\)\\{Bhoot_GirarDerecha\\(255\\);\\}else\\{Bhoot_GirarIzquierda\\(255\\);\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 160,
            yOffset: 405,
          },
        },
      ],
    },
    // Step 12.
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/steps/step12.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,3\\)==(1|2)\\)\\{Bhoot_GirarDerecha\\(255\\);\\}else\\{Bhoot_GirarIzquierda\\(255\\);\\}delay\\(1000\\);\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 470,
          },
        },
      ],
    },
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/steps/step12.png`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);randomSeed\\(analogRead\\(0\\)\\);\\}voidloop\\(\\)\\{Bhoot_Avanzar\\(255\\);if\\(u_distancia\\(A0,A1\\)<${differentThan0Regex}\\)\\{Bhoot_Frenar\\(\\);delay\\(1000\\);if\\(random\\(1,3\\)==(1|2)\\)\\{Bhoot_GirarDerecha\\(255\\);\\}else\\{Bhoot_GirarIzquierda\\(255\\);\\}delay\\(${lessThan1000}\\);\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 145,
            yOffset: 483,
          },
        },
      ],
    },
    // Step 13.
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/steps/step13.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 14.
    {
      step: {
        stepId: 13,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step14`,
        image: `${imgBaseUrl}/steps/step14.gif`,
      },
    },
  ],
};
