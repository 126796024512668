export interface Kit {
  id: CREABOTS_KIT_ID_TYPE,
  name: string,
  urlName: string,
  imageUrl?: string,
  projects: Project[],
  boardId: BOARD_ID_TYPE,
}

export enum CREABOTS_KIT_ID_TYPE {
  INITIATION = 1,
  AUTOMATION = 2,
  DRIVING = 3,
}

export interface Project {
  id: number,
  name: string,
  description: string,
  image: string,
  boardId: BOARD_ID_TYPE,
  tutorial: Tutorial[],
}

export enum BOARD_ID_TYPE {
  INITIATION = 39,
  AUTOMATION = 40,
  MOVEMENT = 41,
}

export type BasicStep = {
  stepId: number,
  modal: MODAL_TYPE,
}

export type VideoStep = BasicStep & {
  component: React.FC<any>,
  videoUrl: string,
}

export type VerifyBoardStep = BasicStep & {
  component: React.FC<any>,
}

export type TutorialSliderStep = BasicStep & {
  component: React.FC<any>,
  images: {text: string, src: string}[],
}

export type StepWithValidation = BasicStep & {
  description?: string,
  image?: string,
  completionCode: string,
  completionCodeCheck: CODE_CHECK_TYPE,
  blockCategory?: number,
  blockSubCategory?: number,
  block?: number,
  canvasDisplacement?: { x: number, y: number },
  resetWorkspace?: boolean, // DELETE THIS.
}

export type LoadProgramStep = BasicStep & {
  description?: string,
  image?: string,
  completionCodeCheck: CODE_CHECK_TYPE,
}

export type FreeGameStep = BasicStep & {
  description: string,
  image: string,
}

export type Step = VideoStep | TutorialSliderStep | VerifyBoardStep | StepWithValidation | LoadProgramStep | FreeGameStep;

export interface Tutorial {
  step: Step,
  hints?: [Hint] | [Hint, Hint]
}

export interface Hint {
  element: {
    class: string,
    image?: string,
    xOffset: number,
    yOffset: number,
  }
}

export enum CODE_CHECK_TYPE {
  EQUAL = 'equal',
  REGEX = 'regex',
  VARIABLE = 'variable',
  BUILT = 'built',
}

export enum MODAL_TYPE {
  FULL = 'full',
  DOCKED = 'docked',
  NORMAL = 'normal',
}

export interface BlockConfig {
  blockCategory: number,
  blockSubCategory?: number,
  canvasDisplacement: { x: number, y: number },
  blocks: Block[],
}

export interface Block {
  id: number,
  className: string,
  image: string,
}

export interface BasicBlock {
  id: number,
  name: string,
}

export enum BLOCK_CATEGORY_TYPE {
  CONTROL = 'control',
  LOGIC = 'logic',
  MATH = 'math',
  VARIABLES = 'variables',
  DIGITAL = 'digital',
  ANALOG = 'analog',
  OUTPUTS = 'outputs',
  DISPLAYS = 'displays',
  TEXT = 'text',
  COMUNICATION = 'comunication',
  MOTORS = 'motors',
}

export enum COMMON_CLASSES {
  INITIAL_BLOCK_CLASS = '.initial_block',
  ADD_VARIABLE_BTN_CLASS = '.blocklyFlyoutButton',
  BLOCKLY_FLYOUT_CLASS = '.blocklyFlyout',
  BUILD_BLOCKLY_BTN_CLASS = '.buildBlockly_btn',
}

export enum KIT_NAME {
  INITIATION = 'initiation',
  AUTOMATION = 'automation',
  DRIVING = 'driving',
}

export enum INITIATION_PROJECTS_TYPE {
  FIRST_STEPS = 'firstSteps',
  MY_FIRST_ROBOT = 'myFirstRobot',
  MUSICAL_BOX = 'musicalBox',
  ROCK_PAPER_SCISSORS = 'rockPaperScissors',
  GUESS_THE_FUTURE = 'guessTheFuture',
  INTELLIGENT_GARDEN = 'intelligentGarden',
  TOUCH_THE_POINT = 'touchThePoint',
  ELECTRONIC_DICE = 'electronicDice',
  GOALS_COUNTER = 'goalsCounter',
}

export enum AUTOMATION_PROJECTS_TYPE {
  AUTOMATIC_LAMP = 'automaticLamp',
  INTRUDER_ALARM = 'intruderAlarm',
  AUTOMATIC_BARRIER = 'automaticBarrier',
  AUTOMATIC_FAN = 'automaticFan',
  CLASSIFIER = 'classifier',
  SMART_ACCESS = 'smartAccess',
  SUPER_GOALKEEPER = 'superGoalkeeper',
  WEATHER_STATION = 'weatherStation',
}

export enum DRIVING_PROJECTS_TYPE {
  SMART_VEHICLE = 'smartVehicle',
  LUNAR_EXPLORER = 'lunarExplorer',
  PAPER_COLLECTOR = 'paperCollector',
  AUTOMATIC_HANDLING = 'automaticHandling',
  SOLAR_VEHICLE = 'solarVehicle',
}

export type ProjectType<K extends KIT_NAME> = K extends KIT_NAME.INITIATION
  ? INITIATION_PROJECTS_TYPE
  : K extends KIT_NAME.AUTOMATION
  ? AUTOMATION_PROJECTS_TYPE
  : K extends KIT_NAME.DRIVING
  ? DRIVING_PROJECTS_TYPE
  : never;