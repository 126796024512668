import { Kit, CREABOTS_KIT_ID_TYPE, BOARD_ID_TYPE } from '@sections/creabots/types/kits'
import firstSteps from './projects/firstSteps'
import myFirstRobot from './projects/myFirstRobot'
import musicalBox from './projects/musicalBox'
import rockPaperScissors from './projects/rockPaperScissors'
import intelligentGarden from './projects/intelligentGarden'
import guessTheFuture from './projects/guessTheFuture'
import touchThePoint from './projects/touchThePoint'
import electronicDice from './projects/electronicDice'
import goalsCounter from './projects/goalsCounter'
import * as routes from '@educabot/educablocks-cosmos';

const initiationKit: Kit = {
  id: CREABOTS_KIT_ID_TYPE.INITIATION,
  name: 'creabots.tutorials.initiation.name',
  urlName: 'iniciacion',
  boardId: BOARD_ID_TYPE.INITIATION,
  imageUrl: `${routes.bloquesUri}/images/creabots/initiation/packaging.png`,
  projects: [
    firstSteps,
    myFirstRobot,
    musicalBox,
    rockPaperScissors,
    guessTheFuture,
    intelligentGarden,
    touchThePoint,
    electronicDice,
    goalsCounter,
  ],
}

export default initiationKit