/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { HashRouter, BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import ToastContainer from '@modules/toasts/containers/toastWrapper';

import Firmware from '@sections/codit/containers/firmware';
import Collaboration from '@sections/collaboration/containers/collaboration';
import CreabotsKit from '@sections/creabots/containers/kit';
import CreabotsProject from '@sections/creabots/containers/project';
import CreabotsUserProject from '@sections/creabots/containers/userProject';
import MachineLearning from '@sections/ai/containers/machineLearning';
import Turns from '@sections/collaboration/containers/turns';
import * as routes from '@educabot/educablocks-cosmos';
import { Helmet } from 'react-helmet';
import CreabotSignUpContainer from '@sections/creabots/containers/singUp';
import CreabotsSignIn from '@sections/creabots/containers/signIn';
import { CreabotsRecoverPassword } from '@sections/creabots/containers/recoverPassword';
import { CreabotsProfiles } from '@sections/creabots/containers/profiles';
import { CreabotsLanding } from '@sections/creabots/containers/landing';
import { ConfigAccount } from '@sections/creabots/containers/configAccount';
import { LabsToast } from '@sections/creabots/components/ui/toast';
import { store } from '../state/store';
import { getLocale } from '../config/locale';
import Login from '../containers/Login';
// import LoginCreatec from '../containers/Login';
import Signup from '../containers/Signup';
import LoginRecover from '../containers/LoginRecover';
import Wrapper from '../containers/App';
import WrapperBlocks from '@sections/blocks/wrapper';
import Dashboard from '../containers/Dashboard';
import Library from '../containers/Library';
import SplashScreen from '../containers/SplashScreen';
import { getFavicon, applyTheme, getCosmosItem } from '../cosmos';
import * as pjson from '../../package.json';

/* eslint-disable func-names */
const routeDisplay = function (_permissions, component, event) {
  return (
    <SplashScreen
      history={event.history}
      match={event.match}
      location={event.location}
      NextComponent={component}
    />
  );
};

const Head = () => {
  const favicon = getFavicon();
  const cosmosTitle = `${getCosmosItem('title')} ${(process.env.IS_ELECTRON) ? `v${pjson.version}` : ''}`;

  return (
    <Helmet>
      {cosmosTitle && <title>{cosmosTitle}</title>}
      {favicon && <link rel="icon" href={favicon} type="image/x-icon" />}
    </Helmet>
  );
};

const App = () => {
  const [baseUrl, setBaseUrl] = useState('localhost');
  const { locale, intlMessages } = getLocale(getCosmosItem('lang') || 'es');

  useEffect(() => {
    setBaseUrl(routes.bloquesUri);
    applyTheme();
  }, []);

  return (
    <Provider store={store}>
      <IntlProvider locale={locale} messages={intlMessages}>
        <Head />
        {(!process.env.IS_ELECTRON) && (
          <BrowserRouter>
            <Switch>
              <Route path="/login" push component={Login} />
              <Route path="/registrar" push component={Signup} />
              <Route path="/recuperar" push component={LoginRecover} />
              <Route path="/proyectos" push render={(event) => routeDisplay(['need2BeLogged'], Dashboard, event)} />
              <Route path="/biblioteca" push render={(event) => routeDisplay(['need2BeLogged'], Library, event)} />
              <Route path="/codigo-:id" push component={Wrapper} />
              <Route path="/codigo" push component={Wrapper} />
              <Route path="/colaboracion-:id" push component={Collaboration} />
              <Route path="/colaboracion" push component={Collaboration} />
              <Route path="/turnos" push component={Turns} />
              <Route path="/remote-:id" push component={Wrapper} />
              <Route path="/actividad-:id" push component={Wrapper} />
              <Route path="/bloques-:id" push component={Wrapper} />
              <Route path="/bloques" push component={Wrapper} />
              <Route path="/bloquesnew-:id" push component={WrapperBlocks} />
              <Route path="/bloquesnew" push component={WrapperBlocks} />
              <Route path="/junior-:id" push component={Wrapper} />
              <Route path="/junior" push component={Wrapper} />
              <Route path="/produccion" push component={Firmware} />

              <Route path="/kits/mis-proyectos" push component={CreabotsKit} />

              <Route path="/kits/registrarse/verificacion" push component={CreabotSignUpContainer} />
              <Route path="/kits/registrarse/contraseña" push component={CreabotSignUpContainer} />
              <Route path="/kits/registrarse" push component={CreabotSignUpContainer} />
              <Route path="/kits/ingresar" push component={CreabotsSignIn} />

              <Route path="/kits/recuperar-contraseña/verificar" push component={CreabotsRecoverPassword} />
              <Route path="/kits/recuperar-contraseña/crear" push component={CreabotsRecoverPassword} />
              <Route path="/kits/recuperar-contraseña" push component={CreabotsRecoverPassword} />

              <Route path="/kits/:kitName/proyecto-:id" push component={CreabotsUserProject} />
              <Route path="/kits/:kitName/proyecto" push component={CreabotsUserProject} />

              <Route path="/kits/:kitName-:id" push component={CreabotsProject} />
              <Route path="/kits/perfiles" push component={CreabotsProfiles} />

              <Route path="/kits/cuenta" push component={ConfigAccount} />


              <Route path="/kits/:productName/:kitName" push component={CreabotsKit} />

              <Route path="/kits/:productName" push component={CreabotsKit} />


              <Route path="/kits" push>
                <Redirect to="/kits/creabots" />
              </Route>

              <Route path="/creabots" push component={CreabotsLanding} />

              <Route path="/machineLearning" push component={MachineLearning} />

              <Route
                exact
                path="/firmware"
                render={() => {
                  window.location.href = '/firmware/index.html';
                }}
              />
              <Route
                exact
                path="/"
                render={() => {
                  if (
                    baseUrl.includes('localhost')
                    || baseUrl.includes('bloques.staging.educabot.com')
                    || baseUrl.includes('robots.educabot.com')
                  ) {
                    window.location.href = '/landing/';
                  } else if (
                    baseUrl.includes('codit.staging.educabot.com')
                    || baseUrl.includes('codit.educabot.com')
                  ) {
                    window.location.href = '/codit/';
                  } else if (
                    baseUrl.includes('creabots.educabot.com')
                  ) {
                    window.location.href = '/creabots';
                  } else if (
                    baseUrl.includes('labs.staging.educabot.com')
                    || baseUrl.includes('labs.educabot.com')
                  ) {
                    window.location.href = '/kits/ingresar';
                  } else {
                    window.location.href = '/bloques';
                  }
                }}
              />
            </Switch>
          </BrowserRouter>
        )}
        {(process.env.IS_ELECTRON) && (
          <HashRouter>
            <Switch>
              <Route path="/bloques" render={(props) => <Wrapper {...props} key={Math.random()} />} />
              <Route path="/junior" render={(props) => <Wrapper {...props} key={Math.random()} />} />
              <Route path="/junior/:id" render={(props) => <Wrapper {...props} key={Math.random()} />} />
              <Route path="/codigo" render={(props) => <Wrapper {...props} key={Math.random()} />} />
              <Route exact path="/" render={(props) => <Wrapper {...props} key={Math.random()} />} />
            </Switch>
          </HashRouter>
        )}
        <ToastContainer />
        <LabsToast />
      </IntlProvider>
    </Provider>
  );
};

export default App;
