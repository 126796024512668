import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1'
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import * as routes from '@educabot/educablocks-cosmos';
import {
  KIT_NAME,
  INITIATION_PROJECTS_TYPE,
  BOARD_ID_TYPE,
  BLOCK_CATEGORY_TYPE,
  CODE_CHECK_TYPE,
  MODAL_TYPE,
  COMMON_CLASSES,
  Project,
} from '@sections/creabots/types/kits'
import { getInitiationConfigForBlock, getInitiationElementForBlock } from '../config'
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.initiation.projects.goalsCounter'
const imgBaseUrl = '/images/creabots/initiation/projects/goalsCounter/steps'

const goalsCounter: Project = {
  id: 9,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}/images/creabots/initiation/projects/goalsCounter/project_image.png`,
  boardId: BOARD_ID_TYPE.INITIATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/goals_counter.mp4'
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.INITIATION, projectName: INITIATION_PROJECTS_TYPE.GOALS_COUNTER, totalSteps: 13 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidsetup(){}voidloop(){if(0==0){}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 65,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1, { x: 300 }),
        completionCode: 'intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)==0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 97,
            yOffset: 77,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        completionCode: 'intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<0\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 415,
            yOffset: 83,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.png`,
        completionCode: 'intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 473,
            yOffset: 83,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 2, { x: 190, ignoreBlock: true }),
        completionCodeCheck: CODE_CHECK_TYPE.VARIABLE,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.ADD_VARIABLE_BTN_CLASS,
            xOffset: 40,
            yOffset: -18,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 1),
        completionCode: 'String\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 20,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 340,
            yOffset: 20,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=0;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 420,
            yOffset: 20,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=1;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 423,
            yOffset: 20,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=1;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\("abc"\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 58,
            yOffset: 145,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=1;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\s*(?!abc")[^"]*\\s*\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 182,
            yOffset: 157,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 0),
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=1;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\s*(?!abc")[^"]*\\s*\\);\\D+\\w*=;\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 65,
            yOffset: 190,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 1),
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=1;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\s*(?!abc")[^"]*\\s*\\);\\D+\\w*=1\\+1;\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 165,
            yOffset: 202,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=1;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\s*(?!abc")[^"]*\\s*\\);\\D+\\w*=\\D+\\w*\\+1;\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.VARIABLES, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 165,
            yOffset: 202,
          },
        },
      ],
    },
    // Step 11.
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/step11.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=1;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\s*(?!abc")[^"]*\\s*\\);\\D+\\w*=\\D+\\w*\\+1;delay\\(1000\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 65,
            yOffset: 230,
          },
        },
      ],
    },
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/step11.png`,
        completionCode: 'int\\D+\\w*;intu_distancia\\(intTriggerPin,intEchoPin\\).*voidsetup\\(\\)\\{\\D+\\w*=1;\\/\\/global_var_\\D+\\w*pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<8\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\s*(?!abc")[^"]*\\s*\\);\\D+\\w*=\\D+\\w*\\+1;delay\\(500\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 146,
            yOffset: 247,
          },
        },
      ],
    },
    // Step 12.
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/step12.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 13.
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/step13.gif`,
      },
    },
    
  ],
}

export default goalsCounter