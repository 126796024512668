import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styles from './tutorialModal.mod.scss';
import clsx from 'clsx';


const TutorialModal = (props) => {
  const intl = useIntl();
  const [minimized, setMinimized] = useState(props.minimized || false);
  const [open, setOpen] = useState(props.open || false);
  const id = props.id || '';
  const children = props.children;
  const type = props.type || 'normal'; // full, normal, docked

  useEffect(() => {
    setMinimized(false);
  }, [props.init]);

  const toggleMinimized = () => {
    if (type === 'docked') {
      setMinimized(!minimized);
    }
  }

  return (
    <>
      <div className={clsx(styles.modalBackdrop, props.showBackdrop ? styles.visible : null)}></div>
      <div className={`${styles.tutorialModal} ${styles[type]} ${(minimized) ? styles.minimized : ''} ${(open) ? styles.visible : ''}`} id={`modalFloating-${id}`}>
        {(type === 'docked') ? (
          <div className={styles.modalToggle} onClick={toggleMinimized}>|||</div>
        ) : ('')}
        <div className={styles.modalDialog}>
          <div className={`${styles.modalContent}`}>
            <div className={styles.modalHeader}></div>
            <div className={styles.modalBody}>{children}</div>
            <div className={styles.modalFooter}></div>
          </div>
        </div>
      </div>
    </>
  );
};


export default TutorialModal;