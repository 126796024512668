/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
let intervalId = null;
let intervalHintId = null;
const handIcon = document.createElement('img');
const handImage = document.createElement('img');
const hintIcon = document.createElement('img');
const hintImage = document.createElement('img');
document.body.appendChild(handIcon);
document.body.appendChild(handImage);
document.body.appendChild(hintIcon);
document.body.appendChild(hintImage);
handIcon.id = 'hand-image-tutorial';
handIcon.style.display = 'none';
handImage.style.display = 'none';
hintIcon.style.display = 'none';
hintImage.style.display = 'none';

const stopHintInterval = () => {
  if (intervalHintId !== null) {
    clearInterval(intervalHintId);
    intervalHintId = null;
  }
};

const stopHandInterval = () => {
  if (intervalId !== null) {
    clearInterval(intervalId);
    intervalId = null;
  }
};

const drawHint = (hint, scale) => {
  const originHint = document.querySelector(hint.class);
  if (originHint) {
    const stepHintImage = hint.image || null;
    const intervalHintVelocity = 700;
    const hintOffsetX = 20 * scale;
    const hintOffsetY = 20 * scale;
    const hintWidth = 70 * scale;
    const hintHeight = 70 * scale;
    const originRect = originHint.getBoundingClientRect();
    const oriX = originRect.left + window.scrollX + ((hint.xOffset || 0) * scale);
    const oriY = originRect.top + window.scrollY + ((hint.yOffset || 0) * scale);
    // if (stepHintImage) {
    //   hintImage.src = stepHintImage;
    //   hintImage.style.position = 'absolute';
    //   hintImage.style.display = 'block';
    //   hintImage.style.zIndex = 1200;
    //   hintImage.style.pointerEvents = 'none';
    //   hintImage.style.transform = `scale(${scale})`;
    //   hintImage.style.transformOrigin = 'top left';
    // }
    hintIcon.src = stepHintImage || '/images/creabots/hint.png';
    hintIcon.style.position = 'absolute';
    hintIcon.style.display = 'block';
    hintIcon.style.zIndex = 1201;
    hintIcon.style.pointerEvents = 'none';

    const resetAndMoveImage = (firstTime = false) => {
      if (intervalHintId || firstTime) {
        hintIcon.style.transition = '';
        hintIcon.style.opacity = '1';
        hintIcon.style.width = `${hintWidth}px`;
        hintIcon.style.height = `${hintHeight}px`;
        hintIcon.style.left = `${(stepHintImage) ? oriX + hintOffsetX : oriX}px`;
        hintIcon.style.top = `${(stepHintImage) ? oriY + hintOffsetY : oriY}px`;
        // if (stepHintImage) {
        //   hintImage.style.transition = '';
        //   hintImage.style.opacity = '1';
        //   hintImage.style.left = `${oriX}px`;
        //   hintImage.style.top = `${oriY}px`;
        // }
        // setTimeout(() => {
        //   const velocity = intervalHintVelocity / 1000;
        //   // hintIcon.style.transition = `left ${velocity}s ease-out, top ${velocity}s ease-out, width ${velocity}s ease-out, height ${velocity}s ease-out, opacity ${velocity / 2}s ease-out`;
        //   // hintIcon.style.opacity = '0';
        //   if (stepHintImage) {
        //     hintImage.style.transition = `left ${velocity}s ease-out, top ${velocity}s ease-out, width ${velocity}s ease-out, height ${velocity}s ease-out, opacity ${velocity / 2}s ease-out`;
        //     hintImage.style.opacity = '0';
        //   }
        // }, 100);
      } else {
        hintIcon.style.opacity = '0';
        hintImage.style.opacity = '0';
      }
    };

    if (!intervalHintId) {
      resetAndMoveImage(true);
      intervalHintId = setInterval(resetAndMoveImage, intervalHintVelocity);
    }
  } else {
    hintIcon.style.opacity = '0';
    hintImage.style.opacity = '0';
  }
};

export const stopMovingHand = () => {
  stopHandInterval();
  stopHintInterval();
  handIcon.style.transition = '';
  handIcon.style.opacity = '0';
  handIcon.style.left = '0px';
  handIcon.style.top = '0px';

  handImage.style.transition = '';
  handImage.style.opacity = '0';
  handImage.style.left = '0px';
  handImage.style.top = '0px';

  hintIcon.style.transition = '';
  hintIcon.style.opacity = '0';
  hintIcon.style.left = '0px';
  hintIcon.style.top = '0px';

  hintImage.style.transition = '';
  hintImage.style.opacity = '0';
  hintImage.style.left = '0px';
  hintImage.style.top = '0px';
};

const getOriginObject = (elementClass = '', workspace = null) => {
  const originAll = document.querySelectorAll(elementClass);
  const origin = Array.from(originAll).filter(element => !workspace.checkedBlocksDB.some(block => block.id === element.parentElement.getAttribute('data-id'))).shift();
  // console.log('=============ws', Array.from(originAll).map((o) => ({ id: o.parentElement.getAttribute('data-id') })), workspace.checkedBlocksDB);
  return origin;
}

export const movingHand = (hints = [], workspace = null) => {
  const scale = workspace.scale * 1.25;
  if (hints[0]?.element?.class) {
    let origin = document.querySelector(hints[0].element.class);
    let destination = null;

    if (origin) {
      const stepHandImage = hints[0].element.image || null;
      destination = document.querySelector(hints[1]?.element?.class);
      if (destination) {
        const intervalVelocity = 3000;
        const handOffsetX = 20 * scale;
        const handOffsetY = 20 * scale;
        const handWidth = 120 * scale;
        const handHeight = 120 * scale;
        if (stepHandImage) {
          handImage.src = stepHandImage;
          handImage.style.position = 'absolute';
          handImage.style.display = 'block';
          handImage.style.pointerEvents = 'none';
          handImage.style.zIndex = 1200;
          handImage.style.transform = `scale(${scale})`;
          handImage.style.transformOrigin = 'top left';
        }
        handIcon.style.position = 'absolute';
        handIcon.style.display = 'block';
        handIcon.style.pointerEvents = 'none';
        handIcon.style.zIndex = 1201;
        handIcon.style.width = `${handWidth}px`;
        handIcon.style.height = `${handHeight}px`;
        drawHint(hints[1].element, scale);

        const resetAndMoveImage = (firstTime = false) => {
          origin = getOriginObject(hints[0]?.element?.class, workspace);
          // origin = document.querySelector(hints[0].element.class);
          const originRect = origin ? origin.getBoundingClientRect() : { width: 0 };
          if (origin && originRect.width && (intervalId || firstTime)) {
            // const originRect = origin.getBoundingClientRect();
            const oriX = originRect.left + window.scrollX + ((hints[0].element.xOffset || 0) * scale);
            const oriY = originRect.top + window.scrollY + ((hints[0].element.yOffset || 0) * scale);
            handIcon.style.opacity = '0';
            handIcon.style.transition = '';
            handIcon.style.left = `${(stepHandImage) ? oriX + handOffsetX : oriX}px`;
            handIcon.style.top = `${(stepHandImage) ? oriY + handOffsetY : oriY}px`;
            if (stepHandImage) {
              handImage.style.opacity = '0';
              handImage.style.transition = '';
              handImage.style.left = `${oriX}px`;
              handImage.style.top = `${oriY}px`;
              handImage.style.width = `${originRect.width}px`;
              handImage.style.height = `${originRect.height}px`;
            }
            setTimeout(() => {
              const destRect = destination.getBoundingClientRect();
              const destX = destRect.left + window.scrollX + ((hints[1].element.xOffset || 0) * scale);
              const destY = destRect.top + window.scrollY + ((hints[1].element.yOffset || 0) * scale);
              const velocity = intervalVelocity / 1000;
              handIcon.style.opacity = '1';
              handIcon.style.transition = `left ${velocity}s ease-out, top ${velocity}s ease-out, opacity 0.1s ease-in-out`;
              handIcon.style.left = `${(stepHandImage) ? destX + handOffsetX : destX}px`;
              handIcon.style.top = `${(stepHandImage) ? destY + handOffsetY : destY}px`;
              if (stepHandImage) {
                handImage.style.opacity = '0.5';
                handImage.style.transition = `left ${velocity}s ease-out, top ${velocity}s ease-out, opacity 0.1s ease-in-out`;
                handImage.style.left = `${destX}px`;
                handImage.style.top = `${destY}px`;
              }
            }, 100);
          } else {
            handIcon.style.opacity = '0';
            handImage.style.opacity = '0';
          }
        };

        if (!intervalId) {
          resetAndMoveImage(true);
          intervalId = setInterval(resetAndMoveImage, intervalVelocity);
        }
      } else {
        drawHint(hints[0].element, scale);
      }
    }
  } else {
    stopMovingHand();
  }
};
