import React, { useEffect } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import * as showToastAction from '@modules/toasts/features/toastsSlice';
import {
  webSerialModalOpen,
  webSerialModalChangeStep,
  webSerialModalChangeState,
  setBuildPort,
  setBuildProgress,
  stopBuild,
  openBuildProgressModal,
} from '@modules/blockly/features/blocklySlice';
import { useBlockly } from '@modules/blockly/features/hooks';
import WebSerialModal from '@modules/blockly/components/webSerialModal';
import BlocklyBuildProgressModal from '@modules/blockly/components/blocklyBuildProgressModal';
import styles from './blocklyBuild.mod.scss';
import { actionPostRemoteSessionCompilation } from '../../../actions/remoteSessionAction';
import { useIntl } from 'react-intl';


const BlocklyBuild = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const blocklyState = useBlockly();
  const bloquesState = useSelector((state) => state.bloquesReducer);

  const setAgent = props.setAgent || null;
  const changeBuildPort = props.changeBuildPort || null;
  const id = props.id || '';
  const board = props.board || {};
  const userAgent = props.userAgent || {};
  const userData = props.userData || {};
  const socket = props.socketAgent || null;
  const compilerBoard = props.compilerBoard || '';
  const isMicrobit = compilerBoard === 'microbit';
  const isChromeOS = (userAgent.platform?.toLowerCase() === 'chromeos' || userAgent.platform?.toLowerCase() === 'chrome os');
  const MainModal = props.mainModal || WebSerialModal;
  const ProgressModal = props.progressModal || BlocklyBuildProgressModal;
  const buildingFunction = props.buildingFunction || null;
  // console.log('=============blocklyState', blocklyState);

  useEffect(() => {
    let selectedPort = blocklyState?.project?.port || '';
    if (bloquesState?.ports?.length > 0) {
      if (!bloquesState.ports.includes(selectedPort)) {
        selectedPort = bloquesState.ports[0];
      }
    }
    dispatch(setBuildPort(selectedPort));
    if (changeBuildPort) {
      changeBuildPort(selectedPort);
    }
  }, [bloquesState?.ports?.length]);

  useEffect(() => {
    if (blocklyState?.build?.building) {
      const codeToSend = blocklyState.project.code;
      const selectedPort = blocklyState.build.port;
      // console.log('=============BUILD', blocklyState, bloquesState, selectedPort, codeToSend);
      if ((!selectedPort && !blocklyState.build.microbitForceDownload) || (!blocklyState.webSerialModal.connected && blocklyState.webSerialModal.step > 0)) {
        dispatch(webSerialModalChangeStep(0));
        dispatch(webSerialModalOpen());
        dispatch(stopBuild());
      } else {
        socket.closeMonitor(selectedPort);
        if (blocklyState.build.compileFromQueueId !== null && (selectedPort !== '' || isMicrobit)) {
          props.showToast({
            title: intl.formatMessage({ id: 'blocks.compilation.compilingTitle' }),
            message: intl.formatMessage({ id: 'blocks.compilation.compiling' }),
            type: TOAST_TYPE.CODE_SENDING,
            options: { autoClose: 3000 }
          });
          // TODO: Add logic to load code from queue
        } else if (blocklyState.build.remoteProjectId) {
          props.showToast({
            title: intl.formatMessage({ id: 'blocks.compilation.compilingToSendTitle' }),
            message: intl.formatMessage({ id: 'blocks.compilation.compilingToSend' }),
            type: TOAST_TYPE.CODE_SENDING,
            options: { autoClose: 3000 }
          });
          // TODO: Add logic to send code to the queue
        } else {
          dispatch(setBuildProgress(0));
          dispatch(openBuildProgressModal());
          socket.uploadToPort(codeToSend, board, selectedPort);
        }
      }
    } else {
      dispatch(stopBuild());
    }
  }, [blocklyState?.build?.building]);

  useEffect(() => {
    const flagMessage = bloquesState.state?.msgs[bloquesState.state?.msgs.length - 1] || '';
    // console.log('==============', flagMessage, bloquesState);
    switch (flagMessage) {
      case '#### BOARD NOT CONNECTED':
        dispatch(webSerialModalChangeState(false, 'ERROR'));
        if (blocklyState.webSerialModal.step === 3) {
          dispatch(webSerialModalChangeStep(blocklyState.webSerialModal.step + 1));
        }
        break;
      case '#### BOARD CONNECTED':
        dispatch(webSerialModalChangeState(true, null));
        dispatch(webSerialModalChangeStep(4));
        break;
      default:
        break;
    }
  }, [bloquesState.state?.msgs.length]);

  const changeWebSerialLink = () => {
    if (socket) {
      socket.connectNewBoard();
    }
    dispatch(webSerialModalChangeState(false, null));
    dispatch(webSerialModalChangeStep(3));
  };

  return (
    <div className={styles.blocklyBuild}>
      <MainModal
        id={id}
        isMicrobit={isMicrobit}
        isChromeOS={isChromeOS}
        changeWebSerialLink={changeWebSerialLink}
        setAgent={setAgent}
        userAgent={userAgent}
        socketAgent={socket}
        buildingFunction={buildingFunction}
      />
      <ProgressModal
        id={id}
        userData={userData}
        isMicrobit={isMicrobit}
      // isChromeOS={isChromeOS}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (params) => dispatch(showToastAction.showToast(params)),
    postRemoteSessionCompilation: (params) => actionPostRemoteSessionCompilation(params),
  }
}

export default connect(null, mapDispatchToProps)(BlocklyBuild);