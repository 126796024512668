import React, { useState } from 'react';
import { Box, Button, Typography, Stack, Modal } from '@mui/material';
import {
  TextTitleThreeEmphasized,
  TextBodyEmphasized,
} from './ui/typographies';
import { CheckCircle } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { deleteCreabotProgress } from '@services/rest/creabots';
import { useHistory } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  projectInfo: {
    title: string;
    image: string;
    description: string;
    progress: number;
  };
  restartProgressInfo: {
    projectId: number;
    creabotsKitId: number;
    tutorialId: number;
  };
  handleClose: () => void;
}

export const RestartProgressModal: React.FC<Props> = ({
  isOpen,
  projectInfo,
  restartProgressInfo,
  handleClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const history = useHistory();

  const handleRestartProgress = async () => {
    setIsLoading(true);
    try {
      await deleteCreabotProgress(restartProgressInfo);
      history.go(0);
    } catch (error) {
      console.error('Error restarting progress', error);
      setIsLoading(false);
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown={isLoading}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '480px',
          width: '100%',
          height: 'fit-content',
          maxHeight: '500px',
          paddingX: theme.spacing(5),
          paddingY: theme.spacing(4),
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <TextTitleThreeEmphasized>
            {intl.formatMessage(
              {
                id: 'creabots.dashboard.restartProgressModal.title',
              },
              { project: `"${intl.formatMessage({ id: projectInfo.title })}"` }
            )}
          </TextTitleThreeEmphasized>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingY={2}
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            gap={2}
          >
            <TextBodyEmphasized textAlign="center">
              {intl.formatMessage({
                id: 'creabots.dashboard.restartProgressModal.description',
              })}
            </TextBodyEmphasized>
            <Box display="flex" alignItems="center" width="100%">
              <Box
                sx={{
                  backgroundColor: '#F8F8F6',
                  width: '200px',
                  margin: '16px',
                  borderRadius: '6px',
                }}
              >
                <img
                  src={projectInfo.image}
                  alt={intl.formatMessage({ id: projectInfo.description || 'description' })}
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                    aspectRatio: '16/9',
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column" width="100%">
                <TextBodyEmphasized>
                  {intl.formatMessage({
                    id: projectInfo.title,
                  })}
                </TextBodyEmphasized>
                <Typography
                  sx={(theme) => ({
                    textAlign: 'left',
                    color: theme.palette.text.secondary,
                    fontWeight: theme.typography.fontWeightMedium,
                    fontSize: '0.9rem',
                    lineHeight: '24px',
                    fontFamily: theme.typography.fontFamily,
                  })}
                >
                  {projectInfo.progress == 100 ? (
                    <Stack direction="row" alignItems="center" gap={1}>
                      {intl.formatMessage(
                        {
                          id: 'creabots.dashboard.restartProgressModal.progressComplete',
                        },
                        {
                          icon: (
                            <CheckCircle
                              color="success"
                              sx={{
                                fontSize: '1.125rem',
                              }}
                            />
                          ),
                        }
                      )}
                    </Stack>
                  ) : (
                    intl.formatMessage(
                      {
                        id: 'creabots.dashboard.restartProgressModal.progress',
                      },
                      { progress: projectInfo.progress }
                    )
                  )}
                </Typography>
              </Box>
            </Box>
            <Button
              fullWidth
              size="small"
              variant="primary"
              onClick={handleRestartProgress}
              disabled={isLoading}
            >
              {intl.formatMessage({
                id: 'common.restart',
              })}
            </Button>
            <Button
              fullWidth
              size="small"
              variant="secondary"
              onClick={handleClose}
              disabled={isLoading}
            >
              {intl.formatMessage({
                id: 'common.cancel',
              })}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
