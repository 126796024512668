/* eslint no-restricted-syntax: 0 */
/* eslint no-await-in-loop: 0 */
/* eslint no-else-return: 0 */
import * as tfcore from '@tensorflow/tfjs-core';
import * as tf from '@tensorflow/tfjs';
import * as mobilenetModule from '@tensorflow-models/mobilenet';
import * as seedrandom from 'seedrandom';
import { Camera } from '../camera';
import { STATE, MODEL_TYPES } from '../params';
import { normalizePlain, flatOneHot, fisherYates, convertToTfDataset } from '../util';


export default class ImagesHandler {
  modelType = MODEL_TYPES.IMAGES;

  state = STATE;

  seed = seedrandom('testSuite');

  trainingParams = {
    denseUnits: 100,
    epochs: 50,
    learningRate: 0.001,
    batchSize: 16,
  };

  wrapper = null;

  camera = null;

  rafId = null;

  classifying = false;

  predictionCallback = null;

  model = null;

  samples = [];

  trainDataset = null;

  validationDataset = null;

  classNamesToIndex = {};

  constructor(wrapper, predictionCallback) {
    this.wrapper = wrapper;
    this.predictionCallback = predictionCallback;
  }

  init = async () => {
    console.log('Handling model Images');
    await this.initDefaultValueMap();
    this.camera = await Camera.setupCamera(this.state.camera, this.wrapper);
    this.renderPrediction();
  }

  initDefaultValueMap = async () => {
    const newState = { ...STATE };

    newState.model = await mobilenetModule.load({
      version: 1,
      alpha: 1.0,
    });

    this.state = newState;
  }

  renderPrediction = async () => {
    await this.renderResult();
    this.rafId = requestAnimationFrame(this.renderPrediction);
    return null;
  }

  renderResult = async () => {
    const camera = this.camera;
    if (camera.video.readyState < 2) {
      await new Promise((resolve) => {
        camera.video.onloadeddata = () => {
          resolve(true);
        };
      });
    }

    if (this.classifying) {
      await this.classify();
    }
    camera.drawCtx();
  }

  classify = async () => {
    if (this.model && this.predictionCallback) {
      const results = {};
      const image = tfcore.browser.fromPixels(this.camera.video);
      const embeddings = this.state.model.infer(image, true);
      const logits = this.model.predict(embeddings);
      const values = await logits.data();
      for (let i = 0; i < values.length; i += 1) {
        const className = Object.keys(this.classNamesToIndex)[i];
        results[className] = values[i].toFixed(4);
      }
      embeddings.dispose();
      logits.dispose();
      this.predictionCallback(results);
    }
  }

  startVideo = async () => {
    this.camera = await Camera.setupCamera(this.state.camera, this.wrapper);
    this.renderPrediction();
  }

  stopVideo = () => {
    window.cancelAnimationFrame(this.rafId);
    this.rafId = null;

    if (this.camera != null) {
      this.camera.video.srcObject.getVideoTracks().forEach((track) => track.stop());
      this.camera.clearCtx();
    }

    if (this.predictionCallback && this.classifying) {
      this.predictionCallback({});
    }
  }

  clearAllSamples = () => {
    this.classifying = false;
    this.samples = [];
    this.model = null;
  }

  startClassifying = () => {
    this.classifying = true;
  }

  stopClassifying = () => {
    this.classifying = false;
  }

  setTrainingParams = (params) => {
    this.trainingParams = {
      ...this.trainingParams,
      ...params,
    };
  }

  getTrainingParams = () => {
    return this.trainingParams;
  }

  addSample = (className) => {
    if (this.camera && className) {
      const image = tfcore.browser.fromPixels(this.camera.video);
      const embeddings = this.state.model.infer(image, true);
      const sample = embeddings.dataSync();

      if (this.classNamesToIndex[className] === undefined) {
        this.classNamesToIndex[className] = Object.keys(this.classNamesToIndex).length || 0;
      }
      const index = this.classNamesToIndex[className];
      if (!this.samples[index]) {
        this.samples[index] = [];
      }
      this.samples[index].push(sample);
      embeddings.dispose();
      image.dispose();
      return this.samples[index].length || 0;
    }
    return 0;
  }

  getSamples = () => {
    return {
      modelType: this.modelType,
      samples: this.samples,
    };
  }

  setSamples = (samples, classesArray) => {
    if (samples.modelType === this.modelType) {
      this.samples = samples.samples;
      const nSamples = {};
      this.samples.map((sampleArr, classIndex) => {
        if (sampleArr && classesArray[classIndex] !== undefined) {
          const className = classesArray[classIndex][0]
          if (this.classNamesToIndex[className] === undefined) {
            this.classNamesToIndex[className] = classIndex;
          }
          nSamples[className] = sampleArr.length;
        }
        return null
      });
      return Object.keys(nSamples).length > 0 ? nSamples : false;
    } else {
      return false;
    }
  }

  prepare = () => {
    for (const classes in this.samples) {
      if (classes.length === 0) {
        throw new Error('Add some examples before training');
      }
    }

    const datasets = convertToTfDataset(this.samples, this.seed);
    this.trainDataset = datasets.trainDataset;
    this.validationDataset = datasets.validationDataset;
  }

  train = async (callbacks) => {
    this.prepare();
    const inputSize = this.samples[0][0].length;

    // const callbacks = {};
    // callbacks.onTrainEnd = (logs) => {
    //   console.log('==========onTrainEnd', logs);
    // };
    // callbacks.onEpochBegin = (epoch, logs) => {
    //   console.log('==========onEpochBegin', epoch, logs);
    // };
    // callbacks.onEpochEnd = (epoch, logs) => {
    //   console.log('==========onEpochEnd', epoch, logs);
    // };

    // in case we need to use a seed for predictable training
    let varianceScaling;
    if (this.seed) {
      varianceScaling = tf.initializers.varianceScaling({ seed: 3.14 });
    } else {
      varianceScaling = tf.initializers.varianceScaling({});
    }

    this.model = tf.sequential({
      layers: [
        // Layer 1.
        tf.layers.dense({
          inputShape: [inputSize],
          units: parseInt(this.trainingParams.denseUnits, 10),
          activation: 'relu',
          kernelInitializer: varianceScaling, // 'varianceScaling'
          useBias: true,
        }),
        // Layer 2 dropout
        // tf.layers.dropout({ rate: 0.5 }),
        // Layer 3. The number of units of the last layer should correspond
        // to the number of classes we want to predict.
        tf.layers.dense({
          units: this.samples.length,
          kernelInitializer: varianceScaling, // 'varianceScaling'
          useBias: false,
          activation: 'softmax',
        }),
      ],
    });

    this.model.setUserDefinedMetadata({ classNames: this.classNamesToIndex });

    const optimizer = tf.train.rmsprop(parseFloat(this.trainingParams.learningRate));
    this.model.compile({
      optimizer,
      loss: 'categoricalCrossentropy',
      metrics: ['accuracy'],
    });

    if (!(parseInt(this.trainingParams.batchSize, 10) > 0)) {
      throw new Error('Batch size is 0 or NaN. Please choose a non-zero fraction');
    }

    const trainData = this.trainDataset.batch(parseInt(this.trainingParams.batchSize, 10));
    const validationData = this.validationDataset.batch(parseInt(this.trainingParams.batchSize, 10));

    await this.model.fitDataset(trainData, {
      epochs: parseInt(this.trainingParams.epochs, 10),
      validationData,
      callbacks,
    });

    optimizer.dispose(); // cleanup

    return this.model;
  }

  save = async (name = 'modelo-educabot-imagenes') => {
    // Save to local storage
    const saveResults = await this.model.save(`localstorage://${name}`);
  }

  load = async (name = 'modelo-educabot-imagenes') => {
    // Load from local storage
    const loadedModel = await tf.loadLayersModel(`localstorage://${name}`);
  }

  export = async (name = 'modelo-educabot-imagenes') => {
    const saveResults = await this.model.save(`downloads://${name}`);
  }

  import = async (json, weights) => {
    this.model = await tf.loadLayersModel(tf.io.browserFiles([json, weights]));
    // this.model = await tf.loadLayersModel(tf.io.browserFiles([json.files[0], weights.files[0]]));
    if (this.model) {
      this.classNamesToIndex = this.model.getUserDefinedMetadata().classNames;
      return this.classNamesToIndex;
    } else {
      return false;
    }
  }
}
