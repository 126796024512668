import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1'
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, CODE_CHECK_TYPE, COMMON_CLASSES, INITIATION_PROJECTS_TYPE, KIT_NAME, MODAL_TYPE, Project } from '@sections/creabots/types/kits'
import * as routes from '@educabot/educablocks-cosmos';
import { getInitiationConfigForBlock, getInitiationElementForBlock } from '../config'
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.initiation.projects.musicalBox'
const imgBaseUrl = '/images/creabots/initiation/projects/musicalBox/steps'

const musicalBox: Project = {
  id: 3,
  name: `${intlPrefix}.name`,
  boardId: BOARD_ID_TYPE.INITIATION,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}/images/creabots/initiation/projects/musicalBox/project_image.png`,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/cajita-musical.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.INITIATION, projectName: INITIATION_PROJECTS_TYPE.MUSICAL_BOX, totalSteps: 11 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidsetup(){}voidloop(){if(0==0){}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 70,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1),
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)==0){}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 95,
            yOffset: 77,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)<0){}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 415,
            yOffset: 84,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.png`,
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)<30){}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 475,
            yOffset: 84,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 6),
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)<30){tone(12,,1000);}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 6),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 130,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 2),
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)<30){tone(12,map(,0,1023,0,255),1000);}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 230,
            yOffset: 140,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1),
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)<30){tone(12,map(u_distancia(A0,A1),0,1023,0,255),1000);}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1),
        },
        {
          element: {
            class: '.advanced_map',
            xOffset: 50,
            yOffset: -18,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)<30){tone(12,map(u_distancia(A0,A1),2,1023,0,255),1000);}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 605,
            yOffset: 145,
          }
        },
      ]
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)<30){tone(12,map(u_distancia(A0,A1),2,30,0,255),1000);}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 665,
            yOffset: 145,
          }
        },
      ]
    },
    // Step 11.
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/step11.png`,
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)<30){tone(12,map(u_distancia(A0,A1),2,30,100,255),1000);}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 730,
            yOffset: 145,
          }
        },
      ]
    },
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/step11.png`,
        completionCode: 'intu_distancia(intTriggerPin,intEchoPin){longduration,distanceCm;digitalWrite(TriggerPin,LOW);delayMicroseconds(4);digitalWrite(TriggerPin,HIGH);delayMicroseconds(10);duration=pulseIn(EchoPin,HIGH);distanceCm=duration*10/292/2;returndistanceCm;}voidsetup(){pinMode(A1,INPUT);pinMode(A0,OUTPUT);}voidloop(){if(u_distancia(A0,A1)<30){tone(12,map(u_distancia(A0,A1),2,30,100,1000),1000);}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 790,
            yOffset: 145,
          }
        },
      ]
    },
    // Step 12.
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/step12.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 13.
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/step13.png`,
      },
    },
  ],
}

export default musicalBox