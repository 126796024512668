import { Kit, CREABOTS_KIT_ID_TYPE, BOARD_ID_TYPE } from '@sections/creabots/types/kits';
import { smartVehicle } from './projects/smartVehicle';
import { lunarExplorer } from './projects/lunarExplorer';
import { paperCollector } from './projects/paperCollector';
import { automaticHandling } from './projects/automaticHandling';
import { solarVehicle } from './projects/solarVehicle';
import * as routes from '@educabot/educablocks-cosmos';

const drivingKit: Kit = {
  id: CREABOTS_KIT_ID_TYPE.DRIVING,
  name: 'creabots.tutorials.driving.name',
  urlName: 'conduccion',
  boardId: BOARD_ID_TYPE.MOVEMENT,
  imageUrl: `${routes.bloquesUri}/images/creabots/driving/packaging.png`,
  projects: [
    smartVehicle,
    lunarExplorer,
    paperCollector,
    automaticHandling,
    solarVehicle,
  ],
};

export default drivingKit;
