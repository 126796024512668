import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styles from './machineLearning.mod.scss';
import Blockly from '@modules/blockly/containers/blockly';
import BlocklyBuild from '@modules/blockly/components/blocklyBuild';
import { boards as Boards, getBoardById } from '@educabot/educablocks-boards';
import { v1 as uuidv1 } from 'uuid';
import { getUserAgent } from '../../../helpers/browserAgent';
import Agent from '../../../helpers/agent';
import { store } from '../../../state/store';
import { build } from '@modules/blockly/features/blocklySlice';
import Bottom from '@sections/ai/components/bottom';
import BuildProgressModal from '@sections/ai/components/buildProgressModal';
import ConnectionModal from '@sections/ai/components/connectionModal';
import { MODEL_TYPES, MODEL_TO_BOARD } from '@utils/hooks/machineLearning/params';


const MachineLearning = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [intervalPortListing, setIntervalPortListing] = useState(null);
  const [id, setId] = useState(0);
  const [workspace, setWorkspace] = useState(null);
  const [blocklyId, setBlocklyId] = useState(uuidv1());
  const [board, setBoard] = useState({});
  const [tabInfo, setTabInfo] = useState({});
  const [socket, setSocket] = useState(null);
  const [userAgent, setUserAgent] = useState({});
  const [iaDataSet, setIaDataSet] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dataSet = searchParams.get('dataset_id') || '';
    setIaDataSet(dataSet);
    const model = searchParams.get('model') || MODEL_TYPES.HANDS;
    const boardId = MODEL_TO_BOARD[model];
    setBoard(getBoardById(Boards, boardId));

    return () => {
      setId(0);
      setWorkspace(false);
      setBlocklyId(uuidv1());
      setSocket(null);
      setBoard({});
    };
  }, []);

  useEffect(() => {
    if (board.id) {
      setTabInfo({
        tagsSelected: [],
        projectId: 0,
        id: blocklyId,
        name: 'Machine Learning',
        description: '',
        userId: 0,
        board,
        port: '',
        agentPorts: [],
        boardName: board.name || '',
        className: board.class || '',
        portOpen: false,
        active: true,
        disabled: false,
        withToolTip: false,
        withWorkspace: false,
        rightPanel: '',
        diagramImageUrl: '',

        boardType: 'senior',
        code: '',
      });

      startSocket(true);
    }
  }, [board]);

  useEffect(() => {
    if (socket && !intervalPortListing) {
      const intervalPortListing = setInterval(() => {
        socket.findPorts();
      }, 1000);
      setIntervalPortListing(intervalPortListing);
    }

    return () => {
      clearInterval(intervalPortListing);
      setIntervalPortListing(null);
    }
  }, [socket?.agentType]);


  const startSocket = () => {
    const newSocket = (board.profile !== 'codit' && board.profile !== 'drone')
      ? new Agent(store, board)
      : null

    if (newSocket?.agentType) {
      getUserAgent().then((ua) => {
        setUserAgent(ua);
        newSocket.setPlatform(ua.platform, 4000);
        // Hack to force redraw in order to update socket.agentType property
        setTimeout(() => {
          setId(1);
        }, 4000);
      });
    }

    setSocket(newSocket);
  }

  const updateWorkspace = (ws) => {
    if (ws) {
      setWorkspace(ws);
    }
  }

  const changeBuildPort = (name) => {
    // setTabInfo({ ...tabInfo, port: name });
  }

  const setAgent = (type = 'serial') => {
    clearInterval(intervalPortListing);
    setIntervalPortListing(null);
    socket.forceAgent(type);
  }

  const buildBlockly = (params) => {
    dispatch(build(params));
  }

  return (
    <div className={styles.machineLearning}>
      <div className={styles.content}>
        {tabInfo.id && board.id ? (
          <>
            <Blockly
              tabInfo={tabInfo}
              socketAgent={socket}
              setWorkspace={updateWorkspace}
              iaDataSet={iaDataSet}
            />
            {socket ? (
              <Bottom
                buildingFunction={buildBlockly}
                board={tabInfo.board}
                socketAgent={socket}
              />
            ) : ('')}
            <BlocklyBuild
              board={tabInfo.board}
              socketAgent={socket}
              id={tabInfo?.id || ''}
              compilerBoard={tabInfo?.board?.compilerBoard || ''}
              userAgent={userAgent}
              userData={{}}
              setAgent={setAgent}
              changeBuildPort={changeBuildPort}
              mainModal={ConnectionModal}
              progressModal={BuildProgressModal}
              buildingFunction={buildBlockly}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MachineLearning;
