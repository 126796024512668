import React, { useState } from 'react';
import {
  alpha,
  Box,
  Chip,
  LinearProgress,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CheckCircle, Replay } from '@mui/icons-material';
import { INITIATION_KIT_TUTORIAL_ID_TYPE } from '@services/rest/creabots/getProjectsProgress';
import { CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits';
import { RestartProgressModal } from './restartProgressModal.tsx';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#000',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    fontSize: '1.4em',
    lineHeight: '20px',
    textAlign: 'center',
    fontFamily: 'DM Sans',
    letterSpacing: '0.03rem',
    fontWeight: 400,
  },
}));

/**
 *
 * @param {Object} props
 * @param {String} props.type // card | item
 * @param {Boolean} props.isBlocked
 * @param {Boolean} props.isCustom
 * @param {String} props.title
 * @param {String} props.image
 * @param {String} props.description
 * @param {string} props.redirectTo
 * @param {Number} props.progress
 * @param {Object} props.restartProgressInfo
 * @returns
 */

export const ProjectCard = (props) => {
  const [openRestartModal, setOpenRestartModal] = useState(false);
  const { type } = props;

  const modalProps = {
    openRestartModal,
    onOpenRestartModal: (e) => {
      e.preventDefault();
      setOpenRestartModal(true);
    },
    onCloseRestartModal: (e) => {
      e.preventDefault();
      setOpenRestartModal(false);
    },
  };

  return (
    <>
      {type === 'card' ? (
        <Card {...props} modalProps={modalProps} />
      ) : (
        <ListItem {...props} modalProps={modalProps} />
      )}
    </>
  );
};

function Card({ modalProps, ...props }) {
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const { openRestartModal, onOpenRestartModal, onCloseRestartModal } = modalProps;
  const {
    title,
    description,
    image,
    isBlocked,
    redirectTo,
    progress,
    restartProgressInfo,
  } = props;
  const intl = useIntl();
  const shouldShowRestartButton = progress > 0 && !isBlocked && !(
    restartProgressInfo.creabotsKitId === CREABOTS_KIT_ID_TYPE.INITIATION
    && restartProgressInfo.tutorialId === INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS
  );

  const BaseCard = (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        elevation: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'min-content',
        width: '100%',
        border: '1px solid',
        borderColor: alpha(theme.palette.common.black, 0.05),
        borderRadius: theme.spacing(1.5),
        '&:hover': {
          boxShadow: isBlocked
            ? 'none'
            : `
        0px 0px 1px 0px rgba(255, 255, 255, 0.1) inset,
        0px 0px 9px 0px rgba(0, 0, 0, 0.2),
        0px 0px 2px 0px rgba(0, 0, 0, 0.25)
      `,
          '--chipVisibility': 'hidden',
          '--boxVisibility': 'visible',
        },
        '--chipVisibility': 'visible',
        '--boxVisibility': 'hidden',
        position: 'relative',
      })}
    >
      <Box
        className="projectCard"
        sx={(theme) => ({
          height: 256,
          width: '100%',
          backgroundColor: isBlocked
            ? theme.palette.secondary.main
            : alpha(theme.palette.secondary.main, 0.08),
          borderStartStartRadius: 'inherit !important',
          borderStartEndRadius: 'inherit !important',
          position: 'relative',
          filter: isBlocked ? 'grayscale(100%)' : 'none',
        })}
      >
        {shouldShowRestartButton && (
          <Box>
            <StyledTooltip
              arrow
              title={intl.formatMessage({
                id: 'creabots.dashboard.projectCard.restartProject',
              })}
              placement="bottom"
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                }}
                onClick={onOpenRestartModal}
              >
                <Replay />
              </IconButton>
            </StyledTooltip>
            <RestartProgressModal
              isOpen={openRestartModal}
              handleClose={onCloseRestartModal}
              projectInfo={{ title, progress, image, description }}
              restartProgressInfo={restartProgressInfo}
            />
          </Box>
        )}

        <img
          src={image}
          alt={intl.formatMessage({ id: description || 'description' })}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
        {isBlocked && (
          <Chip
            sx={(theme) => ({
              margin: theme.spacing(1.5),
              borderRadius: '3px',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: '#DFDFDA',
              color: theme.palette.text.secondary,
              fontWeight: theme.typography.fontWeightBold,
              fontFamily: theme.typography.fontFamily,
              fontSize: '14px',
              padding: 0,
              height: '20px',
              lineHeight: '20px',
              visibility: 'var(--chipVisibility)',
            })}
            label="Bloqueado"
          />
        )}
        {isBlocked && (
          <Box
            sx={(theme) => ({
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'start',
              visibility: 'var(--boxVisibility)',
              borderStartStartRadius: 'inherit !important',
              borderStartEndRadius: 'inherit !important',
              overflow: 'hidden',
              backgroundColor: alpha(theme.palette.common.black, 0.55),
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 10,
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.common.white,
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: '1rem',
                lineHeight: '24px',
                fontFamily: theme.typography.fontFamily,
                paddingX: 1.5,
                paddingBottom: 1.5,
              })}
            >
              {intl.formatMessage({
                id: 'creabots.dashboard.projectCard.finishFirstSteps',
              })}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          paddingY: theme.spacing(1.5),
          paddingX: theme.spacing(2),
          height: isDesktop ? '135px' : '200px',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={(theme) => ({
              textAlign: 'left',
              color: theme.palette.text.primary,
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: '1rem',
              lineHeight: '24px',
              fontFamily: theme.typography.fontFamily,
            })}
          >
            {intl.formatMessage({ id: title })}
          </Typography>
          {progressComponent(progress)}
        </Box>
        <Typography
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '1rem',
            lineHeight: '24px',
            fontFamily: theme.typography.fontFamily,
            textAlign: 'left',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'wrap',
          })}
        >
          {intl.formatMessage({ id: description || 'description' })}
        </Typography>
      </Box>
    </Box>
  );

  return isBlocked ? (
    <Box>{BaseCard}</Box>
  ) : (
    <Link
      to={redirectTo}
      style={{
        textDecoration: 'none',
      }}
    >
      {BaseCard}
    </Link>
  );
}

function ListItem({ modalProps, ...props }) {
  const intl = useIntl();
  const { openRestartModal, onOpenRestartModal, onCloseRestartModal } = modalProps;
  const {
    title,
    description,
    image,
    isBlocked,
    isCustom,
    redirectTo,
    progress,
    restartProgressInfo,
  } = props;
  const [open, setOpen] = useState(false);
  const shouldShowRestartButton = progress > 0 && !isBlocked && !(
    restartProgressInfo.creabotsKitId === CREABOTS_KIT_ID_TYPE.INITIATION
    && restartProgressInfo.tutorialId === INITIATION_KIT_TUTORIAL_ID_TYPE.FIRST_STEPS
  );

  const BaseListItem = (
    <Box
      component="section"
      sx={(theme) => ({
        minHeight: 80,
        maxHeight: 124,
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: isBlocked
            ? theme.palette.common.white
            : alpha(theme.palette.primary.main, 0.08),
        },
      })}
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Box
        width="100%"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          height={80}
          width={192}
          sx={(theme) => ({
            backgroundColor: isBlocked
              ? theme.palette.secondary.main
              : alpha(theme.palette.secondary.main, 0.08),
            filter: isBlocked ? 'grayscale(100%)' : 'none',
            borderRadius: '4px',
          })}
        >
          <img
            src={image}
            alt={intl.formatMessage({ id: description || 'description' })}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>
        <Box
          sx={(theme) => ({
            width: '100%',
            marginLeft: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(3),
            })}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '1rem',
                lineHeight: '24px',
                fontFamily: theme.typography.fontFamily,
              })}
            >
              {intl.formatMessage({ id: title })}
            </Typography>
            {isBlocked && (
              <StyledTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={open}
                disableFocusListener
                disableHoverListener
                arrow
                title={intl.formatMessage({
                  id: 'creabots.dashboard.projectCard.finishFirstSteps',
                })}
                placement="right"
              >
                <Chip
                  sx={(theme) => ({
                    margin: 0,
                    borderRadius: '3px',
                    backgroundColor: '#DFDFDA',
                    color: theme.palette.text.secondary,
                    fontWeight: theme.typography.fontWeightBold,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '14px',
                    padding: 0,
                    height: '20px',
                    lineHeight: '20px',
                  })}
                  label="Bloqueado"
                />
              </StyledTooltip>
            )}
          </Box>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              fontWeight: theme.typography.fontWeightRegular,
              fontSize: '1rem',
              lineHeight: '24px',
              fontFamily: theme.typography.fontFamily,
              textAlign: 'left',
            })}
          >
            {intl.formatMessage({ id: description || 'description' })}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: '30%' }}>{progressComponent(progress)}</Box>
      {shouldShowRestartButton && (
        <Box>
          <StyledTooltip
            arrow
            title={intl.formatMessage({
              id: 'creabots.dashboard.projectCard.restartProject',
            })}
            placement="bottom-start"
          >
            <IconButton onClick={onOpenRestartModal}>
              <Replay />
            </IconButton>
          </StyledTooltip>
          <RestartProgressModal
            isOpen={openRestartModal}
            handleClose={onCloseRestartModal}
            projectInfo={{ title, progress, image, description }}
            restartProgressInfo={restartProgressInfo}
          />
        </Box>
      )}
    </Box>
  );

  return isBlocked ? (
    <Box>{BaseListItem}</Box>
  ) : (
    <Link
      to={redirectTo}
      style={{
        textDecoration: 'none',
      }}
    >
      {BaseListItem}
    </Link>
  );
}

function progressComponent(progress) {
  const intl = useIntl();

  return progress < 100 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <LinearProgress
        value={progress}
        variant="determinate"
        color="primary"
        sx={(theme) => ({
          width: 60,
          marginRight: 1,
        })}
      />
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '0.8rem',
          fontFamily: theme.typography.fontFamily,
        })}
      >
        {intl.formatMessage({ id: 'creabots.inProgress' }, { progress })}
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <CheckCircle color="success" />
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          fontWeight: theme.typography.fontWeightRegular,
          fontSize: '0.8rem',
          fontFamily: theme.typography.fontFamily,
        })}
      >
        {intl.formatMessage({ id: 'creabots.complete' })}
      </Typography>
    </Box>
  );
}
