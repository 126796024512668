import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './bottom.mod.scss';
import clsx from 'clsx';
import { useBlockly } from '@modules/blockly/features/hooks';
import { webSerialModalChangeState, webSerialModalChangeStep } from '@modules/blockly/features/blocklySlice';


const Bottom = ({
  buildingFunction,
  board,
  socketAgent,
}) => {
  const dispatch = useDispatch();
  const blocklyState = useBlockly();
  const agentState = useSelector((state) => state.bloquesReducer);
  const [ebBuildStatus, setEbBuildStatus] = useState('ERROR');
  const [arduinoAgentStatus, setArduinoAgentStatus] = useState('ERROR');

  const socket = socketAgent || null;
  const buildBtnEnabled =
    (
      ((ebBuildStatus === 'ERROR' && !process.env.IS_ELECTRON) || arduinoAgentStatus === 'GOOD')
      && socket.agentType === 'socket'
    )
    || (socket.agentType === 'serial' || socket.agentType === 'usb');

  useEffect(() => {
    const flagMessage = agentState.state?.msgs[agentState.state?.msgs.length - 1] || '';
    // console.log('============MSG', flagMessage, blocklyState.webSerialModal);
    switch (flagMessage) {
      case '#### PORT NOT CONNECTED':
        setEbBuildStatus('ERROR');
        break;
      case '#### PORT CONNECTED':
        setEbBuildStatus('GOOD');
        break;
      case '#### BOARD NOT CONNECTED':
        setArduinoAgentStatus('ERROR');
        break;
      case '#### BOARD CONNECTED':
        setArduinoAgentStatus('GOOD');
        break;
      default:
        break;
    }
  }, [agentState.state?.msgs.length]);

  const build = (params) => {
    if (buildingFunction) {
      buildingFunction(params);
    }
  }

  return (
    <div className={styles.bottom}>
      <button type="button" className={clsx(styles.buildingButton, 'buildBlockly_btn')} disabled={!buildBtnEnabled} onClick={build}>
        <img alt="Educabot" src={`/images/creabots/${arduinoAgentStatus === 'GOOD' ? 'play' : 'connect'}.svg`} />
      </button>
    </div>
  );
};

export default Bottom;