import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import {
  BLOCK_CATEGORY_TYPE,
  BOARD_ID_TYPE,
  CODE_CHECK_TYPE,
  COMMON_CLASSES,
  DRIVING_PROJECTS_TYPE,
  KIT_NAME,
  MODAL_TYPE,
  Project,
} from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getDrivingConfigForBlock, getDrivingElementForBlock } from '../config';
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.driving.projects.solarVehicle';
const imgBaseUrl = '/images/creabots/driving/projects/solarVehicle';

export const solarVehicle: Project = {
  id: 5,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`, //missing image
  boardId: BOARD_ID_TYPE.MOVEMENT,
  tutorial: [
    // Step 1.
        {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: '', //missing video
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({
          kitName: KIT_NAME.DRIVING,
          projectName: DRIVING_PROJECTS_TYPE.SOLAR_VEHICLE,
          totalSteps: 22,
        }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidsetup(){}voidloop(){if(0==0){}}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 70,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.ANALOG, 1),
        completionCode: `voidsetup(){}voidloop(){if(analogRead(A0)==0){}}`,
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.ANALOG, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 95,
            yOffset: 75,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        completionCode: `voidsetup(){}voidloop(){if(analogRead(A0)>0){}}`,
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 340,
            yOffset: 85,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        completionCode: `voidsetup(){}voidloop(){if(analogRead(A0)>300){}}`,
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 403,
            yOffset: 85,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(analogRead\\(A0\\)>300\\)\\{Bhoot_Avanzar\\(255\\);\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 130,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(analogRead\\(A0\\)>300\\)\\{Bhoot_Avanzar\\(255\\);\\}else\\{\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 38,
            yOffset: 181,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(analogRead\\(A0\\)>300\\)\\{Bhoot_Avanzar\\(255\\);\\}else\\{Bhoot_Avanzar\\(255\\);\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 210,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(analogRead\\(A0\\)>300\\)\\{Bhoot_Avanzar\\(255\\);\\}else\\{Bhoot_Frenar\\(\\);\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 140,
            yOffset: 225,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/steps/step10.gif`,
      },
    },
  ],
};
