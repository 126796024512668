import React, { useEffect, useState } from 'react';
import styles from './header.mod.scss';
import { LabsLogo } from '@assets/icons/labsLogo';
import { useIntl } from 'react-intl';
import AppearanceSettings from './appearanceSettings/appearanceSettings';

const Header = (props) => {
  const intl = useIntl();

  return (
    <div className={styles.header}>
      <div className={styles.logoContainer} onClick={props.handleGoHome || null}>
        <LabsLogo width="100%" height={40} />
        <div className={styles.title}>{(props.titleString) ? props.titleString : intl.formatMessage({ id: props.title || 'title' })}</div>
      </div>
      <div className={styles.userContainer}>
        <AppearanceSettings />
        {/* Here, user avatar component */}
      </div>
    </div>
  );
};


export default Header;