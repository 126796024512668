import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styles from './connectionModal.mod.scss';
import { getAgentDownloadLink } from '../../../helpers/AgentDownloadLink';
import { useBlockly } from '@modules/blockly/features/hooks';
import {
  webSerialModalChangeStep,
  webSerialModalClose,
  build,
} from '@modules/blockly/features/blocklySlice';
import { ArrowBackIosNewRounded } from '@mui/icons-material';
import clsx from 'clsx';
import { set } from 'date-fns';


const ConnectionModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const blocklyState = useBlockly();

  const open = blocklyState.webSerialModal.open;
  const id = props.id || '--';
  const isChromeOS = props.isChromeOS || false;
  const isMicrobit = props.isMicrobit || false;
  const userAgent = props.userAgent || {};
  const setAgent = props.setAgent || null;
  const socket = props.socketAgent || null;
  const buildingFunction = props.buildingFunction || null;

  useEffect(() => {
    if (open) {
      $(`#webSerialModal-${id}`).modal('show');
    } else {
      $(`#webSerialModal-${id}`).modal('hide');
    }
  }, [open]);

  useEffect(() => {
    if (blocklyState.webSerialModal.connected && blocklyState.webSerialModal.step === 4) {
      setTimeout(() => {
        dispatch(webSerialModalChangeStep(5));
      }, 3000);
    }

    if (!blocklyState.webSerialModal.connected && blocklyState.webSerialModal.step > 4) {
      dispatch(webSerialModalChangeStep(2));
    }

    if (!blocklyState.webSerialModal.connected && blocklyState.webSerialModal.step === 4) {
      dispatch(webSerialModalChangeStep(2));
    }
  
  }, [blocklyState?.webSerialModal?.connected, blocklyState?.webSerialModal?.step]);

  const changeStep = (step) => {
    dispatch(webSerialModalChangeStep(step));
    if (step === 2 && socket.agentType !== 'serial' && socket.agentType !== 'usb') {
      if (setAgent) {
        setAgent((isMicrobit) ? 'usb' : 'serial');
      }
    }
  };

  const downloadAgent = () => {
    if (setAgent) {
      setAgent('socket');
    }
    const link = getAgentDownloadLink(userAgent);
    window.open(link, '_blank', 'noreferrer');
    dispatch(webSerialModalClose());
  };

  const closeModal = () => {
    dispatch(webSerialModalClose());
  };

  const build = () => {
    dispatch(webSerialModalClose());
    if (buildingFunction) {
      buildingFunction();
    }
  };

  const microbitForceDownload = () => {
    dispatch(webSerialModalClose());
    dispatch(build({ microbitForceDownload: true }));
  };

  const changeLink = () => {
    const { changeWebSerialLink } = props;
    if (changeWebSerialLink) {
      changeWebSerialLink();
    }
  };

  return (
    <div className={clsx('modal', 'fade', 'webSerialModal', styles.connectionModal)} data-backdrop="static" data-keyboard="false" id={`webSerialModal-${id}`} tabIndex="-1" role="dialog" aria-labelledby={`webSerialModal-${id}-label`} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className={clsx('modal-content', styles.content)}>
          <div className={clsx('modal-header', styles.header)}>
            <h5 className="modal-title" id={`webSerialModal-${id}-label`}>
              {blocklyState.webSerialModal.step === 0 ? (
                intl.formatMessage({ id: 'creabots.webserialModal.step0.title' })
              ) : ('')}
              {blocklyState.webSerialModal.step === 2 ? (
                <div className={styles.backLink} onClick={() => changeStep(0)}>
                  <ArrowBackIosNewRounded />
                  {intl.formatMessage({ id: 'common.back' })}
                </div>
              ) : ('')}
              {blocklyState.webSerialModal.step === 3 ? (
                intl.formatMessage({ id: 'creabots.webserialModal.step3.title' })
              ) : ('')}
              {!blocklyState.webSerialModal.connected && blocklyState.webSerialModal.error && blocklyState.webSerialModal.step === 4 ? (
                <div style={{ margin: 'auto' }}>
                  {intl.formatMessage({ id: 'creabots.webserialModal.step4.titleError' })}
                </div>
              ) : ('')}
            </h5>
            <button type="button" className="close" onClick={closeModal} aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>

          <div className={clsx('modal-body', styles.body)}>
            {blocklyState.webSerialModal.step === 0 ? (
              <div className={clsx(styles.twoColumns, styles.buttons)}>
                <div className={styles.columnOne} onClick={downloadAgent}>
                  <img className={styles.image} src="/images/creabots/web_serial/webSerialSelector1.png" alt="" />
                  <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.webserialModal.step0.subtitlePlugin' }) }} />
                  <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.webserialModal.step0.textPlugin' }) }} />
                </div>
                <div className={styles.columnTwo} onClick={() => changeStep(2)} className={styles.columnTwo}>
                  <img className={styles.image} src="/images/creabots/web_serial/webSerialSelector2.png" alt="" />
                  <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.webserialModal.step0.subtitleBrowser' }) }} />
                  <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.webserialModal.step0.textBrowser' }) }} />
                </div>
              </div>
            ) : ('')}
            {blocklyState.webSerialModal.step === 2 || blocklyState.webSerialModal.step === 3 ? (
              <div className={styles.threeColumns}>
                <div className={styles.column}>
                  <div className={styles.instructions}><span className={styles.bullet}>1</span> {intl.formatMessage({ id: 'creabots.webserialModal.step2.messageLine1' })}</div>
                  <div className={styles.instructionsImage}>
                    <img src="/images/creabots/web_serial/webserial_2a.gif" alt="" />
                  </div>
                </div>
                <div className={styles.column}>
                  <div className={styles.instructions}><span className={styles.bullet}>2</span> {intl.formatMessage({ id: 'creabots.webserialModal.step2.messageLine2' })}</div>
                  <div className={styles.instructionsImage}>
                    <button type="button" className="btn btn-primary main-btn" onClick={changeLink}>
                      {intl.formatMessage({ id: 'creabots.webserialModal.buttons.openWindow' })}
                    </button>
                  </div>
                </div>
                <div className={styles.column}>
                  <div className={styles.instructions}><span className={styles.bullet}>3</span> {intl.formatMessage({ id: 'creabots.webserialModal.step2.messageLine3' })}</div>
                  <div className={clsx(styles.instructionsImage, styles.webserial2b)}>
                    <img src="/images/creabots/web_serial/webserial_2b.gif" alt="" />
                  </div>
                </div>
              </div>
            ) : ('')}
            {/* {blocklyState.webSerialModal.step === 3 ? (
              <div className="loading">
                <div><img src="/images/web_serial/linking.png" width="80" alt="" /></div>
                <div>{intl.formatMessage({ id: 'creabots.webserialModal.step3.messageLine1' })}</div>
              </div>
            ) : ('')} */}
            {!blocklyState.webSerialModal.connected && blocklyState.webSerialModal.error && blocklyState.webSerialModal.step === 4 ? (
              <div className={styles.error}>
                <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.webserialModal.step4.messageLine2Error' }) }} />
                <div><img src="/images/creabots/web_serial/webserial_4_error.png" width="120" alt="" /></div>
              </div>
            ) : ('')}
            {blocklyState.webSerialModal.connected && blocklyState.webSerialModal.step === 4 ? (
              <div className={styles.boardLinked}>
                <div><img src="/images/creabots/web_serial/linked.png" width="80" alt="" /></div>
                <div>{intl.formatMessage({ id: 'creabots.webserialModal.step4.titleOk' })}</div>
              </div>
            ) : ('')}
            {blocklyState.webSerialModal.connected && blocklyState.webSerialModal.step === 5 ? (
              <div className={styles.oneColumn}>
                <h5><div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.webserialModal.step4.title' }) }} /></h5>
                <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.webserialModal.step4.messageLine1' }) }} />
                <div><img src="/images/creabots/web_serial/webserial_4.png" width="388" alt="" /></div>
              </div>
            ) : ('')}
          </div>

          <div className="modal-footer">
            {!blocklyState.webSerialModal.connected && blocklyState.webSerialModal.error && blocklyState.webSerialModal.step === 4 ? (
              <button type="button" className="btn btn-primary main-btn" style={{ margin: 'auto' }} onClick={changeLink}>
                {intl.formatMessage({ id: 'creabots.webserialModal.buttons.retry' })}
              </button>
            ) : ('')}
          </div>
        </div>
      </div>
    </div>
  );
};


export default ConnectionModal;