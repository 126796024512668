import Tutorial1VerifyBoard from '@sections/creabots/components/steps/Tutorial1VerifyBoard'
import Tutorial0Step1 from '@sections/creabots/components/steps/Tutorial0Step1'
import Tutorial1Step10 from '@sections/creabots/components/steps/Tutorial1Step10'
import * as routes from '@educabot/educablocks-cosmos';
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, CODE_CHECK_TYPE, COMMON_CLASSES, MODAL_TYPE, Project } from '@sections/creabots/types/kits'
import { getInitiationConfigForBlock, getInitiationElementForBlock } from '../config'

const intlPrefix = 'creabots.tutorials.initiation.projects.firstSteps'
const imgBaseUrl = '/images/creabots/initiation/projects/firstSteps/steps'

const firstSteps: Project = {
  id: 1,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}/images/creabots/initiation/projects/firstSteps/project_image.png`,
  boardId: BOARD_ID_TYPE.INITIATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial0Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/primeros-pasos.mp4'
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1VerifyBoard,
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 0),
        completionCode: '#include<Adafruit_NeoPixel.h>#definePIN13#defineNUMPIXELS2Adafruit_NeoPixelpixels(NUMPIXELS,PIN,NEO_GRB+NEO_KHZ800);voidsetup(){pixels.begin();pixels.setBrightness(30);}voidloop(){pixels.clear();pixels.setPixelColor(0,pixels.Color(51,51,153));pixels.setPixelColor(1,pixels.Color(51,51,153));pixels.show();delayMicroseconds(300);}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 70,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        completionCode: 'pixels\\.setPixelColor\\(0,pixels\\.Color\\((255,204,204|255,102,102|255,0,0|204,0,0|153,0,0|102,0,0)\\)\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 197,
            yOffset: 78,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'delayMicroseconds\\(300\\);delay\\(\\d*\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 115,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 0),
        completionCode: 'delayMicroseconds\\(300\\);delay\\(\\d*\\);pixels\\.setPixelColor\\(0,pixels\\.Color\\(51,51,153\\)\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 152,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.gif`,
        completionCode: 'delayMicroseconds\\(300\\);delay\\(\\d*\\);pixels\\.setPixelColor\\(0,pixels\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 197,
            yOffset: 165,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'pixels\\.setPixelColor\\(1,pixels\\.Color\\((153,255,153|102,255,153|51,255,51|51,204,0|0,153,0|0,102,0|0,51,0)\\)\\);pixels\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(\\d*\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 202,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 0),
        completionCode: 'delayMicroseconds\\(300\\);delay\\(\\d*\\);pixels\\.setPixelColor\\(0,pixels\\.Color\\((51,51,153|51,102,255|51,51,255|0,0,153|0,0,102|51,0,153|102,0,204|102,51,255|102,102,204|153,153,255|204,204,255)\\)\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 239,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'pixels\\.setPixelColor\\(1,pixels\\.Color\\((51,51,153|51,102,255|51,51,255|0,0,153|0,0,102|51,0,153|102,0,204|102,51,255|102,102,204|153,153,255|204,204,255)\\)\\);pixels\\.show\\(\\);delayMicroseconds\\(300\\);delay\\(\\d*\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 276,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 4),
        completionCode: 'voidsetup(){}voidloop(){tone(12,495,1000);}',
        completionCodeCheck: CODE_CHECK_TYPE.EQUAL,
        resetWorkspace: true,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 70,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.gif`,
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{tone\\(12,(?!495)\\d+,\\d+\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 225,
            yOffset: 78,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{tone\\(12,(?!495)\\d+,\\d+\\);delay\\(\\d*\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 115,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 4),
        completionCode: '\\{tone\\(12,(?!495)\\d+,\\d+\\);delay\\(\\d*\\);tone\\(12,495,1000\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 152,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.gif`,
        completionCode: '\\{tone\\(12,(?!495)\\d+,\\d+\\);delay\\(\\d*\\);tone\\(12,(?!495)\\d+,\\d+\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 225,
            yOffset: 163,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: '\\{tone\\(12,(?!495)\\d+,\\d+\\);delay\\(\\d*\\);tone\\(12,(?!495)\\d+,\\d+\\);delay\\(\\d*\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 200,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 4),
        completionCode: 'tone\\(12,(?!495)\\d+,\\d+\\);delay\\(\\d*\\);tone\\(12,495,1000\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 237,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: '\\{tone\\(12,(?!495)\\d+,\\d+\\);delay\\(\\d*\\);tone\\(12,(?!495)\\d+,\\d+\\);delay\\(\\d*\\);tone\\(12,\\d+,\\d+\\);delay\\(\\d*\\);',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 274,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            image: `${routes.bloquesUri}/images/creabots/hint_big.png`,
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -26,
            yOffset: -35,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        component: Tutorial1Step10,
      },
    },
  ],
}

export default firstSteps