import React from 'react';
import { Box, Button, IconButton, Modal } from '@mui/material';
import { TextTitleThreeEmphasized, TextBodyEmphasized } from './typographies';
import { Close } from '@mui/icons-material';
import { useIntl } from 'react-intl';

interface Props {
  isOpen: boolean;
  title: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ConfirmRestartModal: React.FC<Props> = ({
  isOpen,
  title,
  handleClose,
  handleConfirm,
}) => {
  const intl = useIntl();

  const handleCloseModal = () => {
    handleClose();
  };

  const handleConfirmAction = () => {
    handleConfirm();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
    >
      <Box
        sx={theme => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '480px',
          width: '100%',
          height: 'fit-content',
          maxHeight: '400px',
          paddingX: theme.spacing(5),
          paddingY: theme.spacing(4),
        })}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <TextTitleThreeEmphasized>
            {intl.formatMessage({
              id: 'creabots.confirmRestartStep',
            }, { step: title })}
          </TextTitleThreeEmphasized>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingY={2}
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            gap={2}
          >
            <TextBodyEmphasized textAlign="center">
              {intl.formatMessage({
                id: 'creabots.confirmRestartStepText',
              })}
            </TextBodyEmphasized>
            <Button
              fullWidth
              size="small"
              variant="primary"
              onClick={handleConfirmAction}
            >
              {intl.formatMessage({
                id: 'common.restart',
              })}
            </Button>
            <Button
              fullWidth
              size="small"
              variant="secondary"
              onClick={handleCloseModal}
            >
              {intl.formatMessage({
                id: 'common.cancel',
              })}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
